//----------------------
//-  metadata display  -
//----------------------
.l-content .messages--error,
.islandora-objects-display-switch {
	display: none;
	
}
.islandora-openseadragon {
	display: block;
	width: 100%;
	height: 1000px;

	@include green-box;
}

.islandora-book-metadata > div {
	@include green-box;
}

.islandora-metadata {}

.islandora-metadata dl * {
	@include clean;
}

.islandora-metadata {
	dt {
		font-family: $sans-serif-2;
	}

	dt, dd {
		border-color: #B2B3B1;
	}
}

.islandora-objects-grid-item {
	@include outer-container;
}

.islandora-objects-grid-item {
	@include span-columns(1 of 4);

	@include omega(4n);

	img {
		width: 100%;
		line-height: 0;
		padding: 5px;
		border: 1px solid #ccc;
		background: #fff;
	}

	dt {
		@include clean;
	}

	dd {
		text-align: center;
		line-height: 1.1;

		a {
			font-family: $sans-serif-2;
			color: $dark-gray;
			text-transform: uppercase;
			font-weight: 700;
			font-size: .8em;
		}
	}

	dl {
		//border: 1px solid $light-gray;
		border: 1px solid #ccc;
		padding: 10px;
		background: $pale-green;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	}

	dl:hover {
		background: shade($pale-green, 10%);
		transition: background $base-duration $base-timing;

		a {
			color: $light-green;
		}
	}
}
