// Import fonts

@import url(//fonts.googleapis.com/css?family=Lusitana:400,700);

@import url(//fonts.googleapis.com/css?family=Merriweather:400,300,300italic,400italic,700,700italic,900,900italic);

@import url(//fonts.googleapis.com/css?family=Merriweather+Sans:400,300,300italic,400italic,700italic,700,800,800italic);

/*@import url(https://fonts.googleapis.com/css?family=Lato:400,700,300,400italic,700italic,300italic);*/
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700,300italic,400italic,500italic,700italic);

@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700italic,400italic,300italic,700,300);

// Typography

$serif: "Lusitana", serif;
$serif-2: "Merriweather", serif;
$sans-serif: "Roboto", sans-serif;
$sans-serif-2: "Roboto Condensed", sans-serif;
$roboto-condensed: "Roboto Condensed", sans-serif;

/*$sans-serif: 'Merriweather Sans', sans-serif;*/

$base-font-family: $sans-serif;
$heading-font-family: $sans-serif-2;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$blue: #477dca;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;
$dark-green: #38471E;
$light-green: #74933E;
$pale-green: #e0e8e2;
$gold: #ECAD00;
$red: #8c2004;

// Font Colors
$base-font-color: #444;

//$base-font-color: $dark-gray;
$action-color: $red;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;

//mixins

@mixin green-box {
	border: $base-border;
	background: rgba(142, 173, 144, 0.27);
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	padding: 10px;
	margin-bottom: $base-spacing;
}
