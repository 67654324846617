@mixin shadow {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
@mixin hide-me {
	display: none;

	@include media(800px) {
		display: block;
	}
}

//@mixin texture($gradient-angle, $bg-color-1, $bg-color-2, $texture-number, $inverted) {
	//$texture-list: "paper.png" "rice_paper.png" "noise_lines.png" "fabric_plaid.png" "first_aid_kit.png" "texturetastic_gray.png" "lil_fiber.png" "tex2res5.png" "skin_side_up.png" "light_noise_diagonal.png" "chruch.png" "45degreee_fabric.png" "bgnoise_lg.png" "little_pluses.png" "squairy_light.png" "white_texture.png" "binding_light.png" "double_lined.png" "grid.png" "absurdidad.png" "grid_noise.png" "fancy_deboss.png" "graphy.png" "noise_pattern_with_crosslines.png" "old_mathematics.png" "ps_neutral.png" "subtle_freckles.png" "wavegrid.png" "az_subtle.png" "tiny_grid.png" "wavegrid.png" "gridme.png" "noisy_grid.png" "subtlenet2.png" "white_carbon.png" "lghtmesh.png" "p5.png" "worn_dots.png" "subtle_dots.png" "brillant.png" "farmer.png" "retina_dust.png" "pinstripe.png" "line_horizontal1.png" "line_horizontal2.png" "linen.png" "vintage_speckles.png" "subtle_grunge.png" "old_wall.png" "brushed.png" "stucco.png" "mooning.png" "husk.png" "concrete_wall_2.png" "noisy.png" "concrete_wall_3.png" "subtle_surface.png" "dust.png" "light_alu.png" "corrugation.png" "kindajean.png" "line_diagonal1.png" "line_diagonal2.png" "groovepaper.png" "brushed_alu.png" "rough_diagonal.png" "diagonal-noise.png" "cross_scratches.png" "striped_lens.png" "debut_light.png" "diagonal_waves.png" "honey_im_subtle.png" "pw_maze_white.png" "subtle_zebra_3d.png" "white_wave.png" "circles.png" "crosses.png" "grilled.png" "pw_pattern.png" "struckaxiom.png" "vichy.png" "wavecut.png" "whitey.png" "cream_pixels.png" "grey.png" "shinedotted.png" "stacked_circles.png" "noisy_grid_simple.png";

	//@if $inverted == 1 {
		//@include background(url("https://raw.githubusercontent.com/thoughtbot/refills/master/source/images/textures/#{nth($texture-list, $texture-number)}"), linear-gradient($gradient-angle, $bg-color-1, $bg-color-2), center no-repeat $bg-color-1 scroll);
	//}
	//@else {
		//@include background(url("https://raw.githubusercontent.com/thoughtbot/refills/master/source/images/textures/inverted/#{nth($texture-list, $texture-number)}"), linear-gradient($gradient-angle, $bg-color-1, $bg-color-2), center no-repeat $bg-color-1 scroll);
	//}
//}

//// The mixin above should preferably be in a file of its own. Below is the code for the object that has the texture.

//.texture {
	//$texture-top-background: lighten(#ddf0ef, 1%);
	//$texture-bottom-background: darken(#ddf0ef, 10%);

	//height: 5em;
	//width: 100%;

	//.texture-normal {
		//// gradient color 1, gradient color 2, gradient angle, texture-number, 0=inverted or 1=normal
		//@include texture(180deg, $texture-top-background, $texture-bottom-background, 67, 1);

		//float: left;
		//height: 5em;
		//width: 50%;
	//}

	//.texture-inverted {
		//// gradient color 1, gradient color 2, gradient angle, texture-number, 0=inverted or 1=normal
		//@include texture(180deg, $texture-top-background, $texture-bottom-background, 67, 0);

		//float: left;
		//height: 5em;
		//width: 50%;
	//}
//}

@mixin box-emboss($opacity, $opacity2) {
	box-shadow: white($opacity) 0 1px 0, inset black($opacity2) 0 1px 0;
}

@mixin letterpress($opacity) {
	text-shadow: white($opacity) 0 1px 0;
}
