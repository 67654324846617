body {
	color: $base-font-color;
	font-family: $base-font-family;
	font-feature-settings: "kern", "liga", "pnum";
	font-size: $base-font-size;
	line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $heading-font-family;
	font-size: $base-font-size;
	line-height: $heading-line-height;
	margin: 0 0 $small-spacing;
}

h1, h2, h3, p {
	margin: 0;
}

h1 {
	//font-family: $serif;
	font-size: 1.8em;
	font-weight: 700;
	margin-bottom: 0.5em;

	@include media($medium-screen) {
		font-size: 2em;
	}
}

.site-name {
	font-size: 1.5em;
	font-weight: 400;
	margin-bottom: .1em;

	a {
		color: $gold;

		&:hover {
			color: $gold;
		}
	}

	@include media(980px) {
		font-size: 2em;
	}
}

h2 {
	//font-family: $serif;
	font-size: 1.4em;

	color: $light-green;
	// font-style: italic;
	font-weight: 700;

	// line-height: 1.6em;
	margin-bottom: 0.4em;

	@include media($medium-screen) {
		font-size: 1.6em;
	}
}

.site-slogan {
	color: #fff;
	font-weight: 400;
	font-size: 1.2em;
	margin: 0;
}

h3 {
	//font-family: $serif;
	font-size: 1.1em;
	font-weight: 700;
	line-height: 1.3em;
	margin-bottom: .4em;
}

hr {
	border-bottom: 1px solid $light-gray;
	border-left: none;
	border-right: none;
	border-top: none;
	margin: $base-spacing 0;
}

hr {
	/*width: 3em;*/
}

p {
	margin: 0 0 $small-spacing;
	color: $base-font-color;
	line-height: $base-line-height;
	font-family: $sans-serif;

	/*font-size: 0.9em;*/
	/*line-height: 1.6em;*/
	margin-bottom: 1.5em;

	span {
		font-family: $sans-serif;
		font-weight: 700;
	}
}

p + img {
	float: left;
	margin-right: $base-spacing;
}

p.date {
	color: transparentize($base-font-color, 0.6);
	font-family: $serif-2;
	font-size: 0.9em;
	font-style: italic;
	margin-bottom: 0.3em;
}

p.author {
	font-family: $serif-2;
	font-style: italic;
}

a {
	color: $action-color;
	text-decoration: none;
	transition: color $base-duration $base-timing;

	&:active,
	&:focus,
	&:hover {
		color: shade($action-color, 25%);
	}
}

a.read-more {
	display: inline-block;
	font-family: $sans-serif;
	font-size: 0.8em;
	font-weight: 700;
	margin-left: 0.2em;
	position: relative;
	text-transform: uppercase;

	span {
		font-family: $serif;
		font-size: 1.5em;
		font-style: normal;
		position: absolute;
		right: -12px;
		top: -1px;
	}
}

img,
picture {
	margin: 0;
	max-width: 100%;
}

.type {
	border-bottom: 1px solid;
	display: inline-block;
	font-family: $sans-serif;
	font-size: 0.7em;
	font-weight: 700;
	margin-bottom: 2em;
	padding: 0.3em 0;
	text-align: left;
	text-transform: uppercase;
}

code {
	background: #F7F7F7;
	border-radius: $base-border-radius * 1.5;
	border: 1px solid #E0E0E0;
	font-family: monaco;
	font-size: 0.75em;
	font-style: normal;
	padding: 0.1em 0.4em;
	white-space: nowrap;
}
