$visual-grid: true;
$visual-grid-index: front;

.gallery-grid {
	p {
		display: none;
	}

	@include outer-container;

	figure {
		margin: 0;
		border: 1px solid #ccc;
		min-height: 246px;
		margin-bottom: 1em;
		padding: 4px;

		br {
			display: none;
		}

		figcaption {
			font-family: $sans-serif;
			font-size: .8em;
			line-height: 1.2;
		}

		img {
			width: 100% !important;
			height: auto !important;
		}

		@include media(800px) {
			@include span-columns(4);

			&.three {
				@include omega;
			}

			&.six {
				@include omega;
			}
		}
	}
}

.gallery-row {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	a {
		max-height: 88px;
		overflow: hidden;
		margin-bottom: 10px;
		width: 17%;
	}

	a img {
		width: 100% !important;
		height: auto !important;
	}
}

//conference pages

.keynote {
	@include clearfix;
}

.keynote {
	width: 100%;
	border-bottom: 1px solid #ccc;
	padding-bottom: 1em;
	margin-bottom: 1em;

	.imgp_container {
		width:100%;
		@include media(480px){
			width: 200px;
		}
		float: left;
		margin-right: 16px;
	}

	.imgp_container img {
		width: 100% !important;
		height: auto !important;
	}
}
//melanies book launch
.blog__post {
	@include outer-container();
	img {

		@include span-columns(3);
	}
	.blog__text {

		@include span-columns(9);
	}

}
.blog__text {
	display: none;
}
.blog__videos {
	h3 {
	display:none;
	}
	margin-top: $base-spacing * 2;
	margin-bottom: $base-spacing * 2;
	@include media(800px) {
		.blog__video {

			@include span-columns(6);

		}
	}
}
