.slider_button {
	font-weight: 400;
	background: #74933E;
	text-align: center;
	padding: 4px;
	border-radius: 4px;
}

.shadow {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
