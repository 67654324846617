.node-type-lmmi-feature {
	figure.field--name-field-feature-image {
		display: inline-block;

		@include media(700px) {
			float: left;
			width: 25%;
		}


		width: 100%;
		margin: 0 1em 1em 0;
		border: 1px solid #ccc;
		padding: 4px;
		line-height: 0;

		img {
			width: 100%;
		}
	}
}

/*front-page feature*/
.img-border {}

.lp-feature figure {
	margin: 0;
	line-height: 0px;
	width: 60px;

	img {
		@extend .img-border;

		width: 100%;
	}
}

.lp-feature {
	.media-object {
		background: $light-gray;
		border: 1px solid $medium-gray;
		padding: 4px;
		width: 100%;

		@extend .shadow;

		.media-object-section:nth-child(2) {
			width: 100%;

			/* height: auto; */
		}
	}
}

.feature_title {
	margin-bottom: 0;
}

.read_more {
	a {
		border-top: 1px solid #AC1A02;
		border-bottom: 1px solid #AC1A02;
		line-height: 0;
		font-size: .8em;
		margin-bottom: 1em;

		&:hover {
			background: $red;
			color: #fff;
		}
	}
}

/*simons page*/
.page-node-917 .field.field--name-field-feature-image {
	display: none;
}
