/*reset*/
.tabs--primary {
	border-bottom: 0;
	li {
		margin: 0;
		padding: 4p;
	}
	a {
		padding: 0;
		margin: 0;
		background: none;
		&:hover, &:focus {
		background: none;
	}

		&.active {
			background: none;
		}
	}
}

.tabs--primary {
	a {
		/*font-family: "Merriweather Sans", sans-serif;*/
		font-size: .8em;
		font-weight: 100;
	}
	li {
		padding: 5px;
		border-top: 1px solid #ccc;
		border-left: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		&:last-child {
		border-right: 1px solid #ccc;
		border-radius: 0 4px 4px 0;
		}
		&:first-child {
		border-radius: 4px 0 0 4px;
		}
		&.active, &:hover {
		background-color: $red;
		}
		&.active a, &:hover a {
		color: #fff;
		}
	}	
}

