/**
 * $BREADCRUMB
 */


.breadcrumb {
	margin-bottom: .6em;
	font-family: $sans-serif-2;
  @extend %hlist;

  li {
    padding-right: 0.4em;

    &:after {
      content: "»";
      padding-left: 0.4em;
      vertical-align: baseline;
    }
    &:last-child:after {
      content: "";
    }
  }

  a {
    text-decoration: none;
	/*color: $light-green;*/
	color: $light-green;
  }
}
