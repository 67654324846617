div#BRnav, #BRtoolbar {
	box-shadow: none;
}

div#BookReader {
	margin-bottom: $base-spacing;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	border: $base-border;
}

.BRnavCntl, div#BRnav, #BRtoolbar {
	background-color: $pale-green;
}

#BRpage {
	width: initial !important;
}

.ui-widget-content {
	border: 1px solid #ccc;
	background: #ffffff;
	color: #403f3f;
}

#BRreturn a {
	font-size: 15px;
	font-family: $sans-serif;
	display: block;
	color: $light-green;

	/* height: 18px; */
	overflow: hidden;
}
