//@format
.cta_btn {
    @extend button;
}

//fix for 3rd level menus
ul.sf-menu ul {
    overflow: visible !important;
}

.nav-wrapper {
    background: $dark-gray;
    border-bottom: 1px solid #262626;
}

/*=====================================
=            header search            =
=====================================*/

.nav-wrapper {
    padding-left: 10px;
    padding-right: 10px;

    $base-border-color: gainsboro !default;
    $base-border-radius: 3px !default;
    $action-color: #477dca !default;
    $dark-gray: #333 !default;
    $large-screen: 928px !default;
    $navigation-padding: 1em;
    $navigation-background: $dark-gray;
    $navigation-color: transparentize(white, 0.3);
    $navigation-color-hover: $gold;
    $navigation-height: 60px;
    $navigation-nav-button-background: $action-color;
    $navigation-nav-button-background-hover: lighten(
        $navigation-background,
        10%
    );
    $navigation-nav-button-border: 1px solid
        lighten($navigation-nav-button-background, 20%);
    $navigation-search-background: lighten($navigation-background, 5);
    $navigation-search-border: 1px solid darken($navigation-background, 5);
    $navigation-active-link-color: transparentize(white, 0.5);
    $navigation-submenu-padding: 1em;
    $navigation-submenu-width: 13em;
    $horizontal-bar-mode: 1037px;

    background-color: $navigation-background;
    border-bottom: 1px solid darken($navigation-background, 10);
    min-height: $navigation-height;
    width: 100%;

    /*z-index: 999;*/

    .upei-crest {
        float: left;
        max-height: $navigation-height;
        padding-left: $navigation-padding;
        @include media(1037px) {
            padding-right: 2em;
        }
        padding-left: 0;

        img {
            max-height: $navigation-height;
            padding: 0.8em 0;
        }
    }

    .search-bar {
        $search-bar-border-color: $base-border-color;
        $search-bar-border: 1px solid $search-bar-border-color;
        $search-bar-background: lighten($search-bar-border-color, 10%);

        float: right;
        margin-top: 1em;
        width: 12em;

        form {
            position: relative;

            input[type='text'] {
                box-sizing: border-box;
                background: #404040;
                border-radius: 6px;
                border: 1px solid #262626;
                color: rgba(255, 255, 255, 0.7);
                font-size: 0.9em;
                font-style: italic;
                margin: 0;
                padding: 0.5em 0.8em;
                width: 100%;
            }

            input[type='submit'] {
                background: #404040;
                border: none;
                bottom: 0.3em;
                left: auto;
                outline: none;
                padding: 0 9px;
                position: absolute;
                right: 0.3em;
                top: 0.3em;
                overflow: hidden;
                text-indent: 160%;
                white-space: nowrap;
                background-image: url(../images/search-icon.png);
                width: 2em;
                background-size: 19px;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        @include media($horizontal-bar-mode) {
            display: inline-block;
            position: relative;
            width: 16em;

            input {
                box-sizing: border-box;
                display: block;
            }
        }
    }
}

.l-region--navigation p {
    margin: 0;
}

.l-region--navigation {
    display: none;
}

.is-visible .l-region--navigation {
    display: block;
}

@include media(940px) {
    .l-region--navigation {
        display: block;
    }
}

/* ==========================================================================
	superfish menu
   ========================================================================== */

// {{{

/*
 * Sample SCSS for adding Superfish styles to themes.
  *
   * Instructions:
    * 
	 * 1. Change the #block-superfish-1 (below) to the ID of your Superfish menu.
	  *
	   * 2. Add the SCSS to your theme, either as a separate SCSS file or as a part of the main SCSS file.
	    *
		 * 3. Note that by removing the .sf-style-none, the style will be applied to the menu regardless of
		  *    the Style selected in the block configuration.
		   *
		    */

// You can add these variables to your _base.scss
$superfish-1-color: rgba(255, 255, 255, 0.7);
$superfish-1-background-color-level-first: #333;
$superfish-1-background-color-level-second: #333;
$superfish-1-background-color-level-third: #333;
$superfish-1-background-color-level-fourth: #333;

#block-superfish-1 {
    .sf-style-none {
        float: left;
        margin-top: 1em;
        padding: 0;

        &.sf-navbar {
            width: 100%;
            background: $superfish-1-background-color-level-first;
        }

        ul {
            padding-left: 0;

            &.sf-megamenu li {
                &.sf-megamenu-wrapper {
                    background: $superfish-1-background-color-level-fourth;

                    li {
                        background: transparent;
                    }
                }
            }
        }

        a {
            color: $superfish-1-color;
            padding: 0.75em 1em;
            text-decoration: none;
            font-family: $sans-serif;
            font-family: 'Roboto Condensed', sans-serif;
            font-size: 0.9em;
            font-weight: 100;

            &.sf-with-ul {
                padding-right: 2.25em;
            }

            &:focus {
                background: $superfish-1-background-color-level-fourth;
                outline: 0;
                color: $gold;
            }

            &:hover {
                background: $superfish-1-background-color-level-fourth;
                outline: 0;
                color: $gold;
            }

            &:active {
                background: $superfish-1-background-color-level-fourth;
                outline: 0;
                color: $gold;
            }
        }

        span {
            &.nolink {
                color: $superfish-1-color;
                padding: 0.75em 1em;

                &.sf-with-ul {
                    padding-right: 2.25em;
                }

                &:hover {
                    background: $superfish-1-background-color-level-fourth;
                    outline: 0;
                }
            }

            &.sf-description {
                color: $superfish-1-color;
                display: block;
                font-size: 0.833em;
                line-height: 1.5;
                margin: 5px 0 0 5px;
                padding: 0;
            }
        }

        &.rtl {
            span {
                &.sf-description {
                    margin: 5px 5px 0 0;
                }

                &.nolink.sf-with-ul {
                    padding-left: 2.25em;
                    padding-right: 1em;
                }
            }

            a.sf-with-ul {
                padding-left: 2.25em;
                padding-right: 1em;
            }
        }

        li {
            background: $superfish-1-background-color-level-first;

            > ul {
                /*top: 44px;*/
                border-top: 4px solid $gold;

                /*border-bottom: 1px solid $gold; */
                border-bottom: 1px solid #262626;

                a.menuparent:after {
                    float: right;
                    content: '\f101';
                    display: inline-block;
                    font: normal normal normal 14px/1 FontAwesome;
                    font-size: inherit;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }

            li {
                > ul {
                    top: 44px;
                    border-top: 0;
                }

                background: $superfish-1-background-color-level-second;

                li {
                    background: $superfish-1-background-color-level-third;
                }
            }

            &:hover {
                background: $superfish-1-background-color-level-fourth;
                outline: 0;

                > ul {
                    /*top: 40px;*/
                    /*border-top: 4px solid $gold;*/
                }
            }

            &.sfHover {
                background: $superfish-1-background-color-level-fourth;
                outline: 0;

                > ul {
                    /*top: 44px;*/
                }

                > ul ul {
                    top: 0;
                }
            }
        }

        .sf-sub-indicator {
            background-image: url('../images/dropdown-arrows.png');
        }
    }

    div.sf-accordion-toggle {
        &.sf-style-none a {
            background: $superfish-1-background-color-level-first;
            color: $superfish-1-color;
            padding: 1em;

            &.sf-expanded {
                background-color: $superfish-1-background-color-level-fourth;
                font-weight: bold;
            }
        }
    }

    .sf-menu.sf-style-none {
        &.rtl {
            &.sf-accordion li {
                li {
                    a {
                        padding-left: auto;
                        padding-right: 2em;
                    }

                    li {
                        a {
                            padding-left: auto;
                            padding-right: 3em;
                        }

                        li {
                            a {
                                padding-left: auto;
                                padding-right: 4em;
                            }

                            li {
                                a {
                                    padding-left: auto;
                                    padding-right: 5em;
                                }

                                span.nolink {
                                    padding-left: auto;
                                    padding-right: 5em;
                                }
                            }

                            span.nolink {
                                padding-left: auto;
                                padding-right: 4em;
                            }
                        }

                        span.nolink {
                            padding-left: auto;
                            padding-right: 3em;
                        }
                    }

                    span.nolink {
                        padding-left: auto;
                        padding-right: 2em;
                    }
                }
            }

            ul.sf-megamenu {
                li.sf-megamenu-wrapper {
                    ol li {
                        &.sf-megamenu-column {
                            float: right;
                        }
                    }
                }
            }
        }

        &.sf-accordion li {
            &.sf-expanded {
                background-color: $superfish-1-background-color-level-fourth;

                >  {
                    a {
                        font-weight: bold;
                    }

                    span.nolink {
                        font-weight: bold;
                    }
                }
            }

            li {
                a {
                    padding-left: 2em;
                }

                li {
                    a {
                        padding-left: 3em;
                    }

                    li {
                        a {
                            padding-left: 4em;
                        }

                        li {
                            a {
                                padding-left: 5em;
                            }

                            span.nolink {
                                padding-left: 5em;
                            }
                        }

                        span.nolink {
                            padding-left: 4em;
                        }
                    }

                    span.nolink {
                        padding-left: 3em;
                    }
                }

                span.nolink {
                    padding-left: 2em;
                }
            }

            a.sf-accordion-button {
                font-weight: bold;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 499;
            }
        }

        ul.sf-megamenu {
            li.sf-megamenu-wrapper {
                ol {
                    margin: 0;
                    padding: 0;

                    li {
                        margin: 0;
                        padding: 0;

                        &.sf-megamenu-column {
                            display: inline;
                            float: left;
                        }
                    }
                }

                a.menuparent {
                    font-weight: bold;
                }

                span.nolink {
                    &.menuparent {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.sf-menu.sf-horizontal.sf-shadow ul {
    padding-right: 0px !important;
}

#block-superfish-1 .sf-depth-1 > a {
    padding-bottom: 21px;

    &.menuparent:after {
        //[>float: right;<]
        //margin-left: 4px;
        //content: '\f103';
        //display: inline-block;
        //font: normal normal normal 14px/1 FontAwesome;
        //font-size: inherit;
        //text-rendering: auto;
        //-webkit-font-smoothing: antialiased;
        //-moz-osx-font-smoothing: grayscale;
    }
}

#block-superfish-1 .sf-depth-1.sfHover > a {
    background: $gold;
    color: #333;
}

/*=====    section end    ======*/

// }}}

.main-nav--desktop {
    display: none;

    @include media(940px) {
        display: block;
    }
}

.main-nav--mobile {
    clear: both;
    display: block;

    @include media(940px) {
        display: none;
    }
}
