//@format
.bxwrapper {
    margin: 0;
}
.front .l-content h1 {
    //display: none;
    color: $light-green;
}
.pane-node-field-featured-content-blocks {
    .media-object {
        background: $light-gray;
        border: 1px solid $medium-gray;
        padding: 4px;
        width: 100%;

        @extend .shadow;

        .media-object-section:nth-child(2) {
            width: 100%;

            /* height: auto; */
        }
    }
}
.lp-feature {
}

.slider {
}

//setting grid items
.slider {
    grid-area: slider;
}
.welcome {
    grid-area: welcome;
}
.feature-list {
    grid-area: featured-list;
}
.blog-list {
    grid-area: blog-list;
}
.twitter-list {
    grid-area: twitter-list;
}
.featured-image {
    grid-area: featured-image;
    figure {
        margin-top: 1rem;
    }
    * {
        line-height: 0;
    }
}
.bx-wrapper {
    margin-bottom: 0 !important;
}
.lp-grid {
    //display: none;
    @include breakpoint(800px) {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
            'slider slider welcome'
            'featured-list blog-list twitter-list'
            'featured-image featured-image featured-image';
        //'.... featured-image ....';
    }
}
.image-row-image__wrapper {
    display: block;
    &:nth-child(1) {
        padding-left: 0;
    }
    &:nth-child(2) {
        $padding: 6px;
        padding-left: $padding;
        padding-right: $padding;
    }
    &:nth-child(3) {
        padding-right: 0;
    }
}
.image-row-image {
    border: 1px solid #ccc;
    padding: 4px;
    margin-bottom: 1rem;
}
.featured-image .row {
    margin: 0;
    margin-top: 2rem;
    max-width: initial;
}
