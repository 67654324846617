.flex-caption {
	background: rgb(64, 64, 64);
	color: #fff;
	padding: 10px;
}

div#flexslider-1 {
	box-shadow: none !important;
	margin: 0;
	border: 0;
}

.slider img {
	border: 2px solid #ccc;
}
