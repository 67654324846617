



.l-off-canvas-show,
.l-off-canvas-hide {
  display: none;
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  background: transparent url("../img/close.png") center center no-repeat;
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
  text-transform: capitalize; }


.l-off-canvas-show {
  background: transparent url("../img/menu.png") center center no-repeat; }

@media (min-width: 0) and (max-width: 940px) {
  
  .no-js .l-off-canvas {
    position: relative;
    min-height: 24px;
    padding-right: 24px; }
    
    .no-js .l-off-canvas .l-region {
      position: relative;
      overflow: hidden; }
    
    .no-js .l-off-canvas .l-off-canvas-hide {
      display: block; }
    
    .no-js .l-off-canvas:not(:target) .l-region {
      min-height: 0;
      height: 0; }
    
    .no-js .l-off-canvas:not(:target) .l-off-canvas-show {
      display: block; }
    
    .no-js .l-off-canvas:not(:target) .l-off-canvas-hide {
      display: none; }

  
  .js .l-page {
    position: relative;
    height: 100%;
     }

  
  .js .l-off-canvas {
    position: absolute;
    z-index: 99;
    width: 250px;
    top: 0;
    bottom: 0;
    left: -200px;
    padding-top: 24px;
    
    -moz-transition: left 0.2s ease-in;
    -o-transition: left 0.2s ease-in;
    -webkit-transition: left 0.2s ease-in;
    transition: left 0.2s ease-in;
    cursor: pointer; }
    
    .js .l-off-canvas.is-visible {
      left: 0;
      background: #333;
      -webkit-box-shadow: inset 0 0 5px 5px #222;
      -moz-box-shadow: inset 0 0 5px 5px #222;
      box-shadow: inset 0 0 5px 5px #222;
      cursor: auto; }
      
      .js .l-off-canvas.is-visible .l-region--navigation {
        
        padding-bottom: 20px;
        margin-top: 20px; }
      
      .js .l-off-canvas.is-visible .search-bar {
        float: right;
        margin: 0px;
        padding: 10px; }
      
      .js .l-off-canvas.is-visible .upei-crest {
        display: none; }
      
      .js .l-off-canvas.is-visible .l-off-canvas-show {
        display: none; }
      
      .js .l-off-canvas.is-visible .l-off-canvas-hide {
        display: block; }
    
    .js .l-off-canvas .l-off-canvas-show {
      display: block; } }

.l-region.l-region--navigation {
   }

@media (min-width: 0) and (max-width: 940px) {
  
  .l-off-canvas.is-visible .menu-level-1 > ul.menu {
    padding: 0;
    margin: 0 10px 0 20px; }
  
  .l-off-canvas.is-visible ul.menu li {
    display: block;
    margin: 0;
    line-height: 30px; }
    
    .l-off-canvas.is-visible ul.menu li a {
      color: #fff;
      text-decoration: none;
      font-family: "Roboto Condensed", sans-serif;
      font-size: .8em;
      font-weight: 100; }
      
      .l-off-canvas.is-visible ul.menu li a:hover, .l-off-canvas.is-visible ul.menu li a:active {
        color: #ECAD00; }
    
    .l-off-canvas.is-visible ul.menu li ul {
      padding-left: 20px; }
    
    .l-off-canvas.is-visible ul.menu li li a:before {
      content: "\f101";
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-right: 4px; }
  
  .l-off-canvas.is-visible div#block-islandora-solr-simple {
    display: block;
    border-top: 1px solid #4d4d4d;
    width: 100%; }
  
  .l-off-canvas.is-visible form#islandora-solr-simple-search-form {
    width: 220px;
    float: right; } }

.l-header .container,
.l-main,
.l-footer .container {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  *zoom: 1; }
  
  .l-header .container:after,
  .l-main:after,
  .l-footer .container:after {
    content: " ";
    display: block;
    clear: both; }
  
  .l-header .container:after,
  .l-main:after,
  .l-footer .container:after {
    content: "";
    display: table;
    clear: both; }


.l-header,
.l-main,
.l-footer {
  margin-bottom: 20px; }


.l-main,
.branding-container,
.l-footer {
  padding-left: 10px;
  padding-right: 10px; }

@media (min-width: 960px) {
  
  .l-main {
    padding: 2em;
    padding-top: 1em; } }
@media (min-width: 0) and (max-width: 1120px) {
  
  .l-main {
    margin-left: 10px;
    margin-right: 10px; } }


.l-off-canvas {
  clear: both; }

@media (min-width: 44em) {
  
  .l-header .container,
  .l-main,
  .l-footer .container {
    max-width: 1100px; }

  
  .l-region--navigation {
    clear: both; }

  
  .has-sidebar-first .l-content,
  .has-sidebar-second .l-content,
  .has-two-sidebars .l-content {
    width: 66.10169%;
    float: left;
    margin-right: 1.69492%; }
  
  .has-sidebar-first .l-region--sidebar-first,
  .has-sidebar-first .l-region--sidebar-second,
  .has-sidebar-second .l-region--sidebar-first,
  .has-sidebar-second .l-region--sidebar-second,
  .has-two-sidebars .l-region--sidebar-first,
  .has-two-sidebars .l-region--sidebar-second {
    width: 32.20339%;
    float: right;
    margin-right: 0; }
  
  .has-sidebar-first .l-region--sidebar-second,
  .has-sidebar-second .l-region--sidebar-second,
  .has-two-sidebars .l-region--sidebar-second {
    clear: right; } }
@media (min-width: 880px) {
  
  .l-branding {
    width: 74.57627%;
    float: left;
    margin-right: 1.69492%; }

  
  .l-region--header {
    width: 23.72881%;
    float: right;
    margin-right: 0; } }
@media (min-width: 70em) {
  
  .l-branding {
    width: 74.68354%;
    float: left;
    margin-right: 1.26582%; }

  
  .l-region--header {
    width: 24.05063%;
    float: right;
    margin-right: 0; }

  
  .has-sidebar-first .l-content {
    width: 74.68354%;
    float: right;
    margin-right: 0; }
  
  .has-sidebar-first .l-region--sidebar-first {
    width: 24.05063%;
    float: left;
    margin-right: 1.26582%; }

  
  .has-sidebar-second .l-content {
    width: 74.68354%;
    float: left;
    margin-right: 1.26582%; }
  
  .has-sidebar-second .l-region--sidebar-second {
    width: 24.05063%;
    float: right;
    margin-right: 0;
    clear: none; }

  
  .has-two-sidebars .l-content {
    width: 49.36709%;
    float: left;
    margin-right: 1.26582%;
    margin-left: 25.31646%; }
  
  .has-two-sidebars .l-region--sidebar-first,
  .has-two-sidebars .l-region--sidebar-second {
    width: 24.05063%;
    float: left;
    margin-right: 1.26582%; }
  
  .has-two-sidebars .l-region--sidebar-first {
    margin-left: -75.94937%; }
  
  .has-two-sidebars .l-region--sidebar-second {
    float: right;
    margin-right: 0;
    clear: none; } }


