/*about lm*/
.section-about {
	p {
		/*text-align: justify;*/
	}
}

/*works*/
.lmmi-works {
	p { 
	margin: 0;
	 }
	td {
		display: inline-block;
	}

	/*header*/
	tr:nth-child(1) td {
		width: 100%;
		color: $light-green;
	}
	td:first-child {
		width: 15%;
	}

	td:nth-child(2) {
		width: 85%;
	}

}
