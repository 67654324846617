.mini-panel--footer {
	@include outer-container;

	padding-left: 2em;

	@include media(800px) {
		.block--upei-logo {
			@include span-columns(3);
		}

		.block--supporters {
			@include span-columns(3);
		}

		.block--contact {
			@include span-columns(3);
		}

		.menu--social-links {
			@include span-columns(3);
		}
	}
}

.block--upei-logo {
	img {
		max-width: 180px;
	}
}

.block--supporters {}

.block--contact {
	a, span.gc-cs-link {
		color: $gold;
	}
}

.menu--social-links {}

.l-footer {
	background: $dark-gray;
	padding-top: $base-spacing;
	padding-bottom: $base-spacing;
}

.mini-panel--footer {
	color: white;

	p {
		color: white;
	}

	.pane-title {
		font-size: 1.4em;
	}

	.menu {
		li {
			color: white;
			margin-bottom: .5em;

			/*margin-left: 20px;*/
			list-style-type: none;
			font-family: $sans-serif-2;
			list-style-image: none;

			a {
				@include link-colors(#fff, $gold);
			}

			i {
				margin-right: 6px;
				font-size: 1.6em;
				color: $gold;
			}
		}
	}
}

.menu--social-links {
	color: white;

	p {
		color: white;
	}

	.pane-title {
		font-size: 1.4em;
	}

	.menu {
		li {
			color: white;
			margin-bottom: .5em;
			margin-left: 20px;
			list-style-type: none;
			list-style-image: none;

			a {
				@include link-colors(#fff, $gold);
			}

			i {
				margin-right: 6px;
				font-size: 1.6em;
				color: $gold;
			}
		}
	}
}

// .socialmedia { margin: 10px 0; }
div#footer-wrapper {
	background: #333;
}

.upei-footer a {
	color: green;
}

.upei-footer h2 {
	font-size: 20px;
	color: #fff;
}

.upei-footer .bottom-links a {
	color: #ccc;
}

.links-last li a {
	color: #ccc;
}

.upei-footer .links-last, .upei-footer .links-last a {
	color: #ccc;
	font-family: $sans-serif;
	font-weight: 100;
}

.upei-footer {
	font-size: 13px;
}

.bottom-links li {
	line-height: 142%;
}

.upei-footer .address {
	color: #fff;
	margin-bottom: $base-spacing;
}

.upei-footer .links-last {
	margin-bottom: $base-spacing;
}

.upei-footer ul li.leaf {
	list-style-image: none;
	list-style-type: none;
	margin: 0px;
}

.upei-footer ul {
	margin: 0px;
}

.l-footer {
	ul {
		padding: 0;

		li {
			font-family: $sans-serif;
		}
	}
}

.contact-info p {
	font-family: "Roboto Condensed", sans-serif;
}

.footer-login a {
	background: #737272;
	padding: 5px;
	border-radius: 4px;
	font-weight: 400;

	&:hover {
		background: $light-green;
	}
}
