.lp-blog .views-row {
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #ccc;

	h3 {
		margin-bottom: 0;

		a {
			color: $dark-gray;
		}
	}

	p {
		margin-bottom: 0;
	}

	.blog_post_date {
		line-height: 1.3;
		font-family: "Roboto Condensed";
		font-style: italic;
	}
}

.feature h3 a {
	color: $base-font-color;
}

.node--blog--teaser {
	margin: 1em 0;
	padding-bottom: 1em;
	border-bottom: 1px solid #ccc;
	clear: both;
}

.page-blog .action-links {
	float: right;

	a {
		@extend button;
	}
}

.lp-blog h3 {
	margin-bottom: 0;
}

.page-blog .l-content h1 {
	display: inline-block;
	width: 50%;
	float: left;
}

/*landing page*/
/*read more link*/
.field.field-name-node-link a {
	border-top: 1px solid #AC1A02;
	border-bottom: 1px solid #AC1A02;
	line-height: 0;
	font-size: .8em;
	margin-bottom: 1em;

	&:hover {
		background: $red;
		color: #fff;
	}
}

.lp-blog {
	ul.pager {
		text-align: center;
		list-style-type: none;
	}

	ul.pager li {
		display: inline;
		font-size: .9em;
	}
}

.blog_post_date {
	font-size: .9em;
	float: left;
}

.read_more {
	float: right;
}

.feature {
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #ccc;
}

.node-type-blog .submitted {
	display: none;
}

//-------------------
//-  c woster blog  -
//-------------------
//figures
article figure {
	border: $base-border;
	line-height: 0;

	@include clean;

	padding: 5px;
	display: inline-block;

	img {
		width: 100%;
	}

	figcaption {
		line-height: 1.4;
		font-size: .9em;
	}
}

blockquote {
	border-left: 4px solid #ccc;
	padding-left: $small-spacing;
}

.cw01 {
	width: 160px;
	float: left;
	margin-right: $small-spacing;
	margin-bottom: $small-spacing;
}

.cw02 {
	width: 33%;
	float: left;

	//margin-right: $base-spacing;

	margin-bottom: $base-spacing;
}

.cw03 {
	width: 64%;
	float: right;
	margin-bottom: $base-spacing;
}

.cw04 {
	width: 50%;
	display: block;
	margin: 0 auto;
}

.cw01 {}
