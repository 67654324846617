@mixin icon( $content: f118, $position: before ) {
	&:#{$position} {
		//this un-fucks the unicode crap and lets you enter just 'e655' (no quotes) for the $content var.
		content: unquote("\"")+unquote(str-insert($content,"\\", 1))+unquote("\"");
		//***Fontawesome font stuff***
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		@if $position == after {

			margin-left: 4px;

		} @else {

			margin-right: 4px;

		}

	}

}
