.l-header {
	background: $dark_green;
}

a.site-logo {
	@include media(664px) {
		display: inline-block;
		width: 285px;
		float: left;
		height: auto;
		border-right: 1px solid rgba(255, 255, 255, 0.42);
		padding-right: 10px;
		margin-right: 10px;
	}
}

.branding-container.container {
	padding-top: 10px;
	padding-bottom: 10px;
}

nav.social {
	@include horizontal-list;

	ul.menu {
		@include no-bullets;

		float: right;
		margin: 0;
	}

	span.title {
		display: none;
	}

	a {
		font-size: 2em;
			color: lighten($gold, 40%);
		padding-left: 10px;

		&:hover {
		color: $gold;
		}
	}

	// hide on mobile
	display: none;

	@include media(880px) {
		display: block;
	}
}

/*old site link*/
/*nav.social li.last a {*/
    /*font-size: 1em;*/
/*}*/

/*nav.social li.last i {*/
    /*margin-right: 4px;*/
/*}*/
.search-bar {
	@include clearfix;
}
