/*exhibits*/
.exhibit {
    background: rgb(56, 71, 30);
    padding: 4px;
    /*margin-bottom: 10px;*/
}
.exhibits .img-border {
margin-bottom: 1em;
}

.exhibit h3 {
    margin: 0 7px;
    /* display: block; */
}
.exhibit h3 a {
    color: #fff;
	&:hover {
		color: $gold;
	}
}
