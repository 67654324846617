.paragraphs--media-block-right {
  @include outer-container;

  &--text {
    @include span-columns(9);
  }
  &--image {
    border:1px solid #ccc;
    padding: 4px;
    @include span-columns(3);
  }

  &--image--caption {
    font-weight: bold;
  }

}
