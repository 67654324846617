@charset "UTF-8";
/*
 *Not sure if these are revelavent
 */
@import url(//fonts.googleapis.com/css?family=Lusitana:400,700);
@import url(//fonts.googleapis.com/css?family=Merriweather:400,300,300italic,400italic,700,700italic,900,900italic);
@import url(//fonts.googleapis.com/css?family=Merriweather+Sans:400,300,300italic,400italic,700italic,700,800,800italic);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700,300italic,400italic,500italic,700italic);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700italic,400italic,300italic,700,300);
html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

/**
 * $HLIST
 */
.breadcrumb {
  margin: 0;
  padding: 0;
  border: 0; }
  .breadcrumb::after {
    clear: both;
    content: "";
    display: block; }
  .breadcrumb li {
    list-style-image: none;
    list-style-type: none;
    margin-left: 0;
    white-space: nowrap;
    display: inline;
    float: left; }

.slider_button {
  font-weight: 400;
  background: #74933E;
  text-align: center;
  padding: 4px;
  border-radius: 4px; }

.shadow, .lp-feature .media-object, .pane-node-field-featured-content-blocks .media-object {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }

.row {
  max-width: 62.5rem;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.row .row,
.column-row .row {
  margin-left: -0.625rem;
  margin-right: -0.625rem; }

@media screen and (min-width: 40em) {
  .row .row,
  .column-row .row {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem; } }

.row.expanded {
  max-width: none; }

.row.collapse > .column, .row.collapse > .columns {
  padding-left: 0;
  padding-right: 0; }

.column, .columns {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  -webkit-flex: 1 1 0px;
  -ms-flex: 1 1 0px;
  flex: 1 1 0px; }

@media screen and (min-width: 40em) {
  .column, .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; } }

.small-1 {
  -webkit-flex: 0 0 8.33333%;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  -webkit-flex: 0 0 16.66667%;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  -webkit-flex: 0 0 33.33333%;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  -webkit-flex: 0 0 41.66667%;
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  -webkit-flex: 0 0 58.33333%;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  -webkit-flex: 0 0 66.66667%;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  -webkit-flex: 0 0 75%;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  -webkit-flex: 0 0 83.33333%;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  -webkit-flex: 0 0 91.66667%;
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-order-1 {
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1; }

.small-order-2 {
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2; }

.small-order-3 {
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3; }

.small-order-4 {
  -webkit-order: 4;
  -ms-flex-order: 4;
  order: 4; }

.small-order-5 {
  -webkit-order: 5;
  -ms-flex-order: 5;
  order: 5; }

.small-order-6 {
  -webkit-order: 6;
  -ms-flex-order: 6;
  order: 6; }

.small-collapse > .column, .small-collapse > .columns {
  padding-left: 0;
  padding-right: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-left: 0.625rem;
  padding-right: 0.625rem; }

@media screen and (min-width: 40em) {
  .medium-1 {
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .medium-order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2; }
  .medium-order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3; }
  .medium-order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4; }
  .medium-order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5; }
  .medium-order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6; } }

@media screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    -webkit-flex: 1 1 0px;
    -ms-flex: 1 1 0px;
    flex: 1 1 0px; } }

.row.medium-unstack .column, .row.medium-unstack .columns {
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%; }

@media screen and (min-width: 40em) {
  .row.medium-unstack .column, .row.medium-unstack .columns {
    -webkit-flex: 1 1 0px;
    -ms-flex: 1 1 0px;
    flex: 1 1 0px; } }

@media screen and (min-width: 40em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; } }

@media screen and (min-width: 64em) {
  .large-1 {
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .large-order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2; }
  .large-order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3; }
  .large-order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4; }
  .large-order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5; }
  .large-order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6; } }

@media screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    -webkit-flex: 1 1 0px;
    -ms-flex: 1 1 0px;
    flex: 1 1 0px; } }

.row.large-unstack .column, .row.large-unstack .columns {
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%; }

@media screen and (min-width: 64em) {
  .row.large-unstack .column, .row.large-unstack .columns {
    -webkit-flex: 1 1 0px;
    -ms-flex: 1 1 0px;
    flex: 1 1 0px; } }

@media screen and (min-width: 64em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; } }

.shrink {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }

.row.align-right {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.row.align-center {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.row.align-justify {
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.row.align-spaced {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.row.align-top {
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }

.column.align-top, .align-top.columns {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start; }

.row.align-bottom {
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end; }

.column.align-bottom, .align-bottom.columns {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end; }

.row.align-middle {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.column.align-middle, .align-middle.columns {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.row.align-stretch {
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }

.column.align-stretch, .align-stretch.columns {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch; }

.media-object {
  margin-bottom: 1rem;
  display: block; }

.media-object img {
  max-width: none; }

@media screen and (min-width: 0em) and (max-width: 39.9375em) {
  .media-object.stack-for-small .media-object-section {
    display: block;
    padding: 0;
    padding-bottom: 1rem; }
  .media-object.stack-for-small .media-object-section img {
    width: 100%; } }

.media-object-section {
  display: table-cell;
  vertical-align: top; }

.media-object-section:first-child {
  padding-right: 1rem; }

.media-object-section:last-child:not() {
  padding-left: 1rem; }

.media-object-section.middle {
  vertical-align: middle; }

.media-object-section.bottom {
  vertical-align: bottom; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }

.pagination::before, .pagination::after {
  content: ' ';
  display: table; }

.pagination::after {
  clear: both; }

.pagination li {
  font-size: 0.875rem;
  margin-right: 0.0625rem;
  display: none;
  border-radius: 3px; }

.pagination li:last-child, .pagination li:first-child {
  display: inline-block; }

@media screen and (min-width: 40em) {
  .pagination li {
    display: inline-block; } }

.pagination a,
.pagination button,
.pagination .page-blog .action-links a,
.page-blog .action-links .pagination a,
.pagination .cta_btn {
  color: #0a0a0a;
  display: block;
  padding: 0.1875rem 0.625rem;
  border-radius: 3px; }

.pagination a:hover,
.pagination button:hover,
.pagination .page-blog .action-links a:hover,
.page-blog .action-links .pagination a:hover,
.pagination .cta_btn:hover {
  background: #e6e6e6; }

.pagination .current {
  padding: 0.1875rem 0.625rem;
  background: #2ba6cb;
  color: #fefefe;
  cursor: default; }

.pagination .disabled {
  padding: 0.1875rem 0.625rem;
  color: #cacaca;
  cursor: default; }

.pagination .disabled:hover {
  background: transparent; }

.pagination .ellipsis::after {
  content: '…';
  padding: 0.1875rem 0.625rem;
  color: #0a0a0a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  content: '«';
  display: inline-block;
  margin-right: 0.5rem; }

.pagination-next a::after,
.pagination-next.disabled::after {
  content: '»';
  display: inline-block;
  margin-left: 0.5rem; }

/*@import url(https://fonts.googleapis.com/css?family=Lato:400,700,300,400italic,700italic,300italic);*/
/*$sans-serif: 'Merriweather Sans', sans-serif;*/
button, .page-blog .action-links a, .cta_btn, input[type="button"], input[type="reset"], input[type="submit"], .search-facets .block--islandora-solr .islandora-solr-sort a, .islandora-solr-facet-wrapper .soft-limit, .block--islandora-solr-current-query .soft-limit {
  appearance: none;
  background-color: #8c2004;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1.5em;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  button:hover, .page-blog .action-links a:hover, .cta_btn:hover, button:focus, .page-blog .action-links a:focus, .cta_btn:focus, input[type="button"]:hover, input[type="button"]:focus, input[type="reset"]:hover, input[type="reset"]:focus, input[type="submit"]:hover, .search-facets .block--islandora-solr .islandora-solr-sort a:hover, .islandora-solr-facet-wrapper .soft-limit:hover, .block--islandora-solr-current-query .soft-limit:hover, input[type="submit"]:focus, .search-facets .block--islandora-solr .islandora-solr-sort a:focus, .islandora-solr-facet-wrapper .soft-limit:focus, .block--islandora-solr-current-query .soft-limit:focus {
    background-color: #701a03;
    color: #fff; }
  button:disabled, .page-blog .action-links a:disabled, .cta_btn:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, input[type="submit"]:disabled, .search-facets .block--islandora-solr .islandora-solr-sort a:disabled, .islandora-solr-facet-wrapper .soft-limit:disabled, .block--islandora-solr-current-query .soft-limit:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    button:disabled:hover, .page-blog .action-links a:disabled:hover, .cta_btn:disabled:hover, input[type="button"]:disabled:hover, input[type="reset"]:disabled:hover, input[type="submit"]:disabled:hover, .search-facets .block--islandora-solr .islandora-solr-sort a:disabled:hover, .islandora-solr-facet-wrapper .soft-limit:disabled:hover, .block--islandora-solr-current-query .soft-limit:disabled:hover {
      background-color: #8c2004; }

/* Adjacent buttons should have some space between them. */
.button + .button,
.button + a {
  margin-left: 1em; }

.green {
  background-color: blue;
  color: #fff; }
  .green:hover, .green:focus {
    background-color: #0000cc;
    color: #fff; }

fieldset {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  margin: 0 0 0.75em;
  padding: 1.5em; }

input,
label,
select {
  font-family: "Roboto", sans-serif;
  font-size: 1em; }

label {
  font-weight: 600;
  margin-bottom: 0.375em; }
  label.required::after {
    content: "*"; }
  label abbr {
    display: none; }

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea,
select[multiple=multiple] {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  margin-bottom: 0.75em;
  padding: 0.5em;
  transition: border-color 150ms ease;
  width: 100%; }
  input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, input:not([type]):hover, textarea:hover,
  select[multiple=multiple]:hover {
    border-color: #b1b1b1; }
  input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, input:not([type]):focus, textarea:focus,
  select[multiple=multiple]:focus {
    border-color: #8c2004;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(115, 26, 3, 0.7);
    outline: none; }
  input[type="color"]:disabled, input[type="date"]:disabled, input[type="datetime"]:disabled, input[type="datetime-local"]:disabled, input[type="email"]:disabled, input[type="month"]:disabled, input[type="number"]:disabled, input[type="password"]:disabled, input[type="search"]:disabled, input[type="tel"]:disabled, input[type="text"]:disabled, input[type="time"]:disabled, input[type="url"]:disabled, input[type="week"]:disabled, input:not([type]):disabled, textarea:disabled,
  select[multiple=multiple]:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed; }
    input[type="color"]:disabled:hover, input[type="date"]:disabled:hover, input[type="datetime"]:disabled:hover, input[type="datetime-local"]:disabled:hover, input[type="email"]:disabled:hover, input[type="month"]:disabled:hover, input[type="number"]:disabled:hover, input[type="password"]:disabled:hover, input[type="search"]:disabled:hover, input[type="tel"]:disabled:hover, input[type="text"]:disabled:hover, input[type="time"]:disabled:hover, input[type="url"]:disabled:hover, input[type="week"]:disabled:hover, input:not([type]):disabled:hover, textarea:disabled:hover,
    select[multiple=multiple]:disabled:hover {
      border: 1px solid #ddd; }

textarea {
  resize: vertical; }

input[type="search"] {
  appearance: none; }

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: 0.375em; }
  input[type="checkbox"] + label,
  input[type="radio"] + label {
    display: inline-block; }

input[type="file"] {
  margin-bottom: 0.75em;
  width: 100%; }

select {
  margin-bottom: 1.5em;
  max-width: 100%;
  width: auto; }

dl {
  margin-bottom: 0.75em; }
  dl dt {
    font-weight: bold;
    margin-top: 0.75em; }
  dl dd {
    margin: 0; }

/**
 * $HLIST
 */
.block--system-user-menu .menu, .block--nav-bar .menu, .action-links, .breadcrumb {
  margin: 0;
  padding: 0;
  border: 0;
  /* for IE 6/7 */
  *zoom: expression(this.runtimeStyle.zoom="1", this.appendChild(document.createElement("br")).style.cssText="clear:both;font:0/0 serif");
  /* non-JS fallback */
  *zoom: 1; }

.block--system-user-menu .menu:before, .block--nav-bar .menu:before, .action-links:before, .breadcrumb:before, .block--system-user-menu .menu:after, .block--nav-bar .menu:after, .action-links:after, .breadcrumb:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden; }

.block--system-user-menu .menu:after, .block--nav-bar .menu:after, .action-links:after, .breadcrumb:after {
  clear: both; }

.block--system-user-menu .menu li, .block--nav-bar .menu li, .action-links li, .breadcrumb li {
  list-style-image: none;
  list-style-type: none;
  margin-left: 0;
  white-space: nowrap;
  display: inline;
  float: left; }

table {
  border-collapse: collapse;
  font-feature-settings: "kern", "liga", "tnum";
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid #a6a6a6;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

td {
  border-bottom: 1px solid #ddd;
  padding: 0.75em 0; }

tr,
td,
th {
  vertical-align: middle; }

body {
  color: #444;
  font-family: "Roboto", sans-serif;
  font-feature-settings: "kern", "liga", "pnum";
  font-size: 1em;
  line-height: 1.5; }

h1,
h2,
.search-facets h3,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1em;
  line-height: 1.2;
  margin: 0 0 0.75em; }

h1,
h2,
.search-facets h3, h3, p {
  margin: 0; }

h1 {
  font-size: 1.8em;
  font-weight: 700;
  margin-bottom: 0.5em; }
  @media screen and (min-width: 600px) {
    h1 {
      font-size: 2em; } }

.site-name {
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: .1em; }
  .site-name a {
    color: #ECAD00; }
    .site-name a:hover {
      color: #ECAD00; }
  @media screen and (min-width: 980px) {
    .site-name {
      font-size: 2em; } }


h2,
.search-facets h3 {
  font-size: 1.4em;
  color: #74933E;
  font-weight: 700;
  margin-bottom: 0.4em; }
  @media screen and (min-width: 600px) {
    
    h2,
    .search-facets h3 {
      font-size: 1.6em; } }

.site-slogan {
  color: #fff;
  font-weight: 400;
  font-size: 1.2em;
  margin: 0; }

h3 {
  font-size: 1.1em;
  font-weight: 700;
  line-height: 1.3em;
  margin-bottom: .4em; }

hr {
  border-bottom: 1px solid #ddd;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: 1.5em 0; }

hr {
  /*width: 3em;*/ }

p {
  margin: 0 0 0.75em;
  color: #444;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  /*font-size: 0.9em;*/
  /*line-height: 1.6em;*/
  margin-bottom: 1.5em; }
  p span {
    font-family: "Roboto", sans-serif;
    font-weight: 700; }

p + img {
  float: left;
  margin-right: 1.5em; }

p.date {
  color: rgba(68, 68, 68, 0.4);
  font-family: "Merriweather", serif;
  font-size: 0.9em;
  font-style: italic;
  margin-bottom: 0.3em; }

p.author {
  font-family: "Merriweather", serif;
  font-style: italic; }

a {
  color: #8c2004;
  text-decoration: none;
  transition: color 150ms ease; }
  a:active, a:focus, a:hover {
    color: #691803; }

a.read-more {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 0.8em;
  font-weight: 700;
  margin-left: 0.2em;
  position: relative;
  text-transform: uppercase; }
  a.read-more span {
    font-family: "Lusitana", serif;
    font-size: 1.5em;
    font-style: normal;
    position: absolute;
    right: -12px;
    top: -1px; }

img,
picture {
  margin: 0;
  max-width: 100%; }

.type {
  border-bottom: 1px solid;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 0.7em;
  font-weight: 700;
  margin-bottom: 2em;
  padding: 0.3em 0;
  text-align: left;
  text-transform: uppercase; }

code {
  background: #F7F7F7;
  border-radius: 4.5px;
  border: 1px solid #E0E0E0;
  font-family: monaco;
  font-size: 0.75em;
  font-style: normal;
  padding: 0.1em 0.4em;
  white-space: nowrap; }

body.cke_editable {
  background: none; }

.img-border, .lp-feature figure img {
  border: 1px solid #ccc;
  padding: 4px;
  background: #fff;
  line-height: 0; }

.conf_chair {
  *zoom: 1;
  clear: both;
  margin-bottom: 1em; }
  .conf_chair:before, .conf_chair:after {
    display: table;
    content: '';
    line-height: 0; }
  .conf_chair:after {
    clear: both; }
  .conf_chair .imgp_container {
    width: 14%;
    margin-right: 1em; }
    .conf_chair .imgp_container img {
      width: 100%; }

.conf_banner_2016 {
  background: #e0e8e2; }
  .conf_banner_2016 img {
    height: auto !important; }

.conf_banner_2016 {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  /*background: rgba(142, 173, 144, 0.27);*/
  padding: 5px;
  margin-bottom: 2em;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }
  @media screen and (min-width: 800px) {
    .conf_banner_2016 {
      flex-direction: row; } }
  .conf_banner_2016 img {
    /* height: auto !important; */
    /* display: block; */
    flex: 1; }
  .conf_banner_2016 .caption {
    flex: 1;
    /* margin-left: 2em; */
    text-align: center; }
  .conf_banner_2016 h2, .conf_banner_2016 .search-facets h3, .search-facets .conf_banner_2016 h3 {
    font-size: 2em; }
  .conf_banner_2016 h3 {
    font-size: 1.5em; }

/*about lm*/
.section-about p {
  /*text-align: justify;*/ }

/*works*/
.lmmi-works {
  /*header*/ }
  .lmmi-works p {
    margin: 0; }
  .lmmi-works td {
    display: inline-block; }
  .lmmi-works tr:nth-child(1) td {
    width: 100%;
    color: #74933E; }
  .lmmi-works td:first-child {
    width: 15%; }
  .lmmi-works td:nth-child(2) {
    width: 85%; }

/*exhibits*/
.exhibit {
  background: #38471e;
  padding: 4px;
  /*margin-bottom: 10px;*/ }

.exhibits .img-border, .exhibits .lp-feature figure img, .lp-feature figure .exhibits img {
  margin-bottom: 1em; }

.exhibit h3 {
  margin: 0 7px;
  /* display: block; */ }

.exhibit h3 a {
  color: #fff; }
  .exhibit h3 a:hover {
    color: #ECAD00; }

.lp-blog .views-row {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc; }
  .lp-blog .views-row h3 {
    margin-bottom: 0; }
    .lp-blog .views-row h3 a {
      color: #333; }
  .lp-blog .views-row p {
    margin-bottom: 0; }
  .lp-blog .views-row .blog_post_date {
    line-height: 1.3;
    font-family: "Roboto Condensed";
    font-style: italic; }

.feature h3 a {
  color: #444; }

.node--blog--teaser {
  margin: 1em 0;
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc;
  clear: both; }

.page-blog .action-links {
  float: right; }

.lp-blog h3 {
  margin-bottom: 0; }

.page-blog .l-content h1 {
  display: inline-block;
  width: 50%;
  float: left; }

/*landing page*/
/*read more link*/
.field.field-name-node-link a {
  border-top: 1px solid #AC1A02;
  border-bottom: 1px solid #AC1A02;
  line-height: 0;
  font-size: .8em;
  margin-bottom: 1em; }
  .field.field-name-node-link a:hover {
    background: #8c2004;
    color: #fff; }

.lp-blog ul.pager {
  text-align: center;
  list-style-type: none; }

.lp-blog ul.pager li {
  display: inline;
  font-size: .9em; }

.blog_post_date {
  font-size: .9em;
  float: left; }

.read_more {
  float: right; }

.feature {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc; }

.node-type-blog .submitted {
  display: none; }

article figure {
  border: 1px solid #ddd;
  line-height: 0;
  margin: 0;
  padding: 0;
  padding: 5px;
  display: inline-block; }
  article figure img {
    width: 100%; }
  article figure figcaption {
    line-height: 1.4;
    font-size: .9em; }

blockquote {
  border-left: 4px solid #ccc;
  padding-left: 0.75em; }

.cw01 {
  width: 160px;
  float: left;
  margin-right: 0.75em;
  margin-bottom: 0.75em; }

.cw02 {
  width: 33%;
  float: left;
  margin-bottom: 1.5em; }

.cw03 {
  width: 64%;
  float: right;
  margin-bottom: 1.5em; }

.cw04 {
  width: 50%;
  display: block;
  margin: 0 auto; }

div#BRnav, #BRtoolbar {
  box-shadow: none; }

div#BookReader {
  margin-bottom: 1.5em;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border: 1px solid #ddd; }

.BRnavCntl, div#BRnav, #BRtoolbar {
  background-color: #e0e8e2; }

#BRpage {
  width: initial !important; }

.ui-widget-content {
  border: 1px solid #ccc;
  background: #ffffff;
  color: #403f3f; }

#BRreturn a {
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  display: block;
  color: #74933E;
  /* height: 18px; */
  overflow: hidden; }

/**
 * $BREADCRUMB
 */
.breadcrumb {
  margin-bottom: .6em;
  font-family: "Roboto Condensed", sans-serif; }
  .breadcrumb li {
    padding-right: 0.4em; }
    .breadcrumb li:after {
      content: "»";
      padding-left: 0.4em;
      vertical-align: baseline; }
    .breadcrumb li:last-child:after {
      content: ""; }
  .breadcrumb a {
    text-decoration: none;
    /*color: $light-green;*/
    color: #74933E; }

.node-type-lmmi-feature figure.field--name-field-feature-image {
  display: inline-block;
  width: 100%;
  margin: 0 1em 1em 0;
  border: 1px solid #ccc;
  padding: 4px;
  line-height: 0; }
  @media screen and (min-width: 700px) {
    .node-type-lmmi-feature figure.field--name-field-feature-image {
      float: left;
      width: 25%; } }
  .node-type-lmmi-feature figure.field--name-field-feature-image img {
    width: 100%; }

/*front-page feature*/
.lp-feature figure {
  margin: 0;
  line-height: 0px;
  width: 60px; }
  .lp-feature figure img {
    width: 100%; }

.lp-feature .media-object {
  background: #ddd;
  border: 1px solid #999;
  padding: 4px;
  width: 100%; }
  .lp-feature .media-object .media-object-section:nth-child(2) {
    width: 100%;
    /* height: auto; */ }

.feature_title {
  margin-bottom: 0; }

.read_more a {
  border-top: 1px solid #AC1A02;
  border-bottom: 1px solid #AC1A02;
  line-height: 0;
  font-size: .8em;
  margin-bottom: 1em; }
  .read_more a:hover {
    background: #8c2004;
    color: #fff; }

/*simons page*/
.page-node-917 .field.field--name-field-feature-image {
  display: none; }

#imagepicker body {
  background: none; }

#imagepicker .messages {
  display: none; }

#imagepicker .imgp_help {
  display: none; }

#imagepicker .form-item-thumb-1 {
  width: 45%;
  float: left; }

#imagepicker .form-item-scale-1 {
  width: 45%;
  float: right; }

#imagepicker .form-item-title-1 {
  clear: both; }

body#imagepicker {
  background: none;
  /* END Buttons */
  color: #000;
  background: #fff;
  font: normal 13px/1.538em Open Sans, "Segoe UI", "Helvetica", sans-serif; }
  body#imagepicker label {
    font: normal 1.2em/1em Open Sans, "Segoe UI", "Helvetica", sans-serif;
    /*font-size: 20px;*/
    font-weight: 300;
    color: #0074BD; }
  body#imagepicker input[type="text"] {
    font-size: 1em;
    font-family: Open Sans, "Segoe UI", "Helvetica", sans-serif; }
  body#imagepicker form#imagepicker-image-form {
    clear: both;
    padding-left: 5px; }

.form-type-checkbox {
  display: inline-block; }

.form-item-imagepicker-browser-search-by-name {
  width: 20px; }

.form-item-imagepicker-browser-search-by-desc {
  width: 20px; }

.form-item-imagepicker-browser-search-by-title {
  width: 20px; }

.l-content .messages--error,
.islandora-objects-display-switch {
  display: none; }

.islandora-openseadragon {
  display: block;
  width: 100%;
  height: 1000px;
  border: 1px solid #ddd;
  background: rgba(142, 173, 144, 0.27);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 10px;
  margin-bottom: 1.5em; }

.islandora-book-metadata > div {
  border: 1px solid #ddd;
  background: rgba(142, 173, 144, 0.27);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 10px;
  margin-bottom: 1.5em; }

.islandora-metadata dl * {
  margin: 0;
  padding: 0; }

.islandora-metadata dt {
  font-family: "Roboto Condensed", sans-serif; }

.islandora-metadata dt, .islandora-metadata dd {
  border-color: #B2B3B1; }

.islandora-objects-grid-item {
  *zoom: 1;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto; }
  .islandora-objects-grid-item:before, .islandora-objects-grid-item:after {
    display: table;
    content: '';
    line-height: 0; }
  .islandora-objects-grid-item:after {
    clear: both; }

.islandora-objects-grid-item {
  float: left;
  display: block;
  margin-right: 6.66667%;
  width: 20%; }
  .islandora-objects-grid-item:last-child {
    margin-right: 0; }
  .islandora-objects-grid-item:nth-child(4n) {
    margin-right: 0; }
  .islandora-objects-grid-item:nth-child(4n+1) {
    clear: left; }
  .islandora-objects-grid-item img {
    width: 100%;
    line-height: 0;
    padding: 5px;
    border: 1px solid #ccc;
    background: #fff; }
  .islandora-objects-grid-item dt {
    margin: 0;
    padding: 0; }
  .islandora-objects-grid-item dd {
    text-align: center;
    line-height: 1.1; }
    .islandora-objects-grid-item dd a {
      font-family: "Roboto Condensed", sans-serif;
      color: #333;
      text-transform: uppercase;
      font-weight: 700;
      font-size: .8em; }
  .islandora-objects-grid-item dl {
    border: 1px solid #ccc;
    padding: 10px;
    background: #e0e8e2;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }
  .islandora-objects-grid-item dl:hover {
    background: #cad1cb;
    transition: background 150ms ease; }
    .islandora-objects-grid-item dl:hover a {
      color: #74933E; }

.ks figure {
  margin: 0;
  padding: 0;
  background: #e0e8e2;
  padding: 5px;
  margin: 0; }

.ks strong {
  color: #74933E; }

.postcard p {
  margin: 0; }

.postcard ul {
  /*list-style: none;*/
  margin: 0;
  /*padding: 0;*/ }

.node-type-ks-page ul.breadcrumb {
  display: none  !important; }

.node-type-ks-page strong {
  color: #74933E; }

.node-type-ks-page .postcard {
  float: left;
  max-width: 50%;
  margin: 0 1em 1em 0;
  background: #e0e8e2;
  /*background: rgba(142, 173, 144, 0.27);*/ }
  .node-type-ks-page .postcard h2, .node-type-ks-page .postcard .search-facets h3, .search-facets .node-type-ks-page .postcard h3 {
    margin: 0;
    padding: 0;
    margin-top: 4px; }

.bxwrapper {
  margin: 0; }

.front .l-content h1 {
  color: #74933E; }

.pane-node-field-featured-content-blocks .media-object {
  background: #ddd;
  border: 1px solid #999;
  padding: 4px;
  width: 100%; }
  .pane-node-field-featured-content-blocks .media-object .media-object-section:nth-child(2) {
    width: 100%;
    /* height: auto; */ }

.slider {
  grid-area: slider; }

.welcome {
  grid-area: welcome; }

.feature-list {
  grid-area: featured-list; }

.blog-list {
  grid-area: blog-list; }

.twitter-list {
  grid-area: twitter-list; }

.featured-image {
  grid-area: featured-image; }
  .featured-image figure {
    margin-top: 1rem; }
  .featured-image * {
    line-height: 0; }

.bx-wrapper {
  margin-bottom: 0 !important; }

@media screen and (min-width: 800px) {
  .lp-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'slider slider welcome' 'featured-list blog-list twitter-list' 'featured-image featured-image featured-image'; } }

.image-row-image__wrapper {
  display: block; }
  .image-row-image__wrapper:nth-child(1) {
    padding-left: 0; }
  .image-row-image__wrapper:nth-child(2) {
    padding-left: 6px;
    padding-right: 6px; }
  .image-row-image__wrapper:nth-child(3) {
    padding-right: 0; }

.image-row-image {
  border: 1px solid #ccc;
  padding: 4px;
  margin-bottom: 1rem; }

.featured-image .row {
  margin: 0;
  margin-top: 2rem;
  max-width: initial; }

.l-off-canvas-show,
.l-off-canvas-hide {
  display: none;
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  background: transparent url("../img/close.png") center center no-repeat;
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
  text-transform: capitalize; }

.l-off-canvas-show {
  background: transparent url("../img/menu.png") center center no-repeat; }

@media (min-width: 0) and (max-width: 940px) {
  .no-js .l-off-canvas {
    position: relative;
    min-height: 24px;
    padding-right: 24px; }
  .no-js .l-off-canvas .l-region {
    position: relative;
    overflow: hidden; }
  .no-js .l-off-canvas .l-off-canvas-hide {
    display: block; }
  .no-js .l-off-canvas:not(:target) .l-region {
    min-height: 0;
    height: 0; }
  .no-js .l-off-canvas:not(:target) .l-off-canvas-show {
    display: block; }
  .no-js .l-off-canvas:not(:target) .l-off-canvas-hide {
    display: none; }
  .js .l-page {
    position: relative;
    height: 100%; }
  .js .l-off-canvas {
    position: absolute;
    z-index: 99;
    width: 250px;
    top: 0;
    bottom: 0;
    left: -200px;
    padding-top: 24px;
    -moz-transition: left 0.2s ease-in;
    -o-transition: left 0.2s ease-in;
    -webkit-transition: left 0.2s ease-in;
    transition: left 0.2s ease-in;
    cursor: pointer; }
  .js .l-off-canvas.is-visible {
    left: 0;
    background: #333;
    -webkit-box-shadow: inset 0 0 5px 5px #222;
    -moz-box-shadow: inset 0 0 5px 5px #222;
    box-shadow: inset 0 0 5px 5px #222;
    cursor: auto; }
  .js .l-off-canvas.is-visible .l-region--navigation {
    padding-bottom: 20px;
    margin-top: 20px; }
  .js .l-off-canvas.is-visible .search-bar {
    float: right;
    margin: 0px;
    padding: 10px; }
  .js .l-off-canvas.is-visible .upei-crest {
    display: none; }
  .js .l-off-canvas.is-visible .l-off-canvas-show {
    display: none; }
  .js .l-off-canvas.is-visible .l-off-canvas-hide {
    display: block; }
  .js .l-off-canvas .l-off-canvas-show {
    display: block; } }

@media (min-width: 0) and (max-width: 940px) {
  .l-off-canvas.is-visible .menu-level-1 > ul.menu {
    padding: 0;
    margin: 0 10px 0 20px; }
  .l-off-canvas.is-visible ul.menu li {
    display: block;
    margin: 0;
    line-height: 30px; }
  .l-off-canvas.is-visible ul.menu li a {
    color: #fff;
    text-decoration: none;
    font-family: "Roboto Condensed", sans-serif;
    font-size: .8em;
    font-weight: 100; }
  .l-off-canvas.is-visible ul.menu li a:hover, .l-off-canvas.is-visible ul.menu li a:active {
    color: #ECAD00; }
  .l-off-canvas.is-visible ul.menu li ul {
    padding-left: 20px; }
  .l-off-canvas.is-visible ul.menu li li a:before {
    content: "\f101";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 4px; }
  .l-off-canvas.is-visible div#block-islandora-solr-simple {
    display: block;
    border-top: 1px solid #4d4d4d;
    width: 100%; }
  .l-off-canvas.is-visible form#islandora-solr-simple-search-form {
    width: 220px;
    float: right; } }

.l-header .container,
.l-main,
.l-footer .container {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  *zoom: 1; }

.l-header .container:after,
.l-main:after,
.l-footer .container:after {
  content: " ";
  display: block;
  clear: both; }

.l-header .container:after,
.l-main:after,
.l-footer .container:after {
  content: "";
  display: table;
  clear: both; }

.l-header,
.l-main,
.l-footer {
  margin-bottom: 20px; }

.l-main,
.branding-container,
.l-footer {
  padding-left: 10px;
  padding-right: 10px; }

@media (min-width: 960px) {
  .l-main {
    padding: 2em;
    padding-top: 1em; } }

@media (min-width: 0) and (max-width: 1120px) {
  .l-main {
    margin-left: 10px;
    margin-right: 10px; } }

.l-off-canvas {
  clear: both; }

@media (min-width: 44em) {
  .l-header .container,
  .l-main,
  .l-footer .container {
    max-width: 1100px; }
  .l-region--navigation {
    clear: both; }
  .has-sidebar-first .l-content,
  .has-sidebar-second .l-content,
  .has-two-sidebars .l-content {
    width: 66.10169%;
    float: left;
    margin-right: 1.69492%; }
  .has-sidebar-first .l-region--sidebar-first,
  .has-sidebar-first .l-region--sidebar-second,
  .has-sidebar-second .l-region--sidebar-first,
  .has-sidebar-second .l-region--sidebar-second,
  .has-two-sidebars .l-region--sidebar-first,
  .has-two-sidebars .l-region--sidebar-second {
    width: 32.20339%;
    float: right;
    margin-right: 0; }
  .has-sidebar-first .l-region--sidebar-second,
  .has-sidebar-second .l-region--sidebar-second,
  .has-two-sidebars .l-region--sidebar-second {
    clear: right; } }

@media (min-width: 880px) {
  .l-branding {
    width: 74.57627%;
    float: left;
    margin-right: 1.69492%; }
  .l-region--header {
    width: 23.72881%;
    float: right;
    margin-right: 0; } }

@media (min-width: 70em) {
  .l-branding {
    width: 74.68354%;
    float: left;
    margin-right: 1.26582%; }
  .l-region--header {
    width: 24.05063%;
    float: right;
    margin-right: 0; }
  .has-sidebar-first .l-content {
    width: 74.68354%;
    float: right;
    margin-right: 0; }
  .has-sidebar-first .l-region--sidebar-first {
    width: 24.05063%;
    float: left;
    margin-right: 1.26582%; }
  .has-sidebar-second .l-content {
    width: 74.68354%;
    float: left;
    margin-right: 1.26582%; }
  .has-sidebar-second .l-region--sidebar-second {
    width: 24.05063%;
    float: right;
    margin-right: 0;
    clear: none; }
  .has-two-sidebars .l-content {
    width: 49.36709%;
    float: left;
    margin-right: 1.26582%;
    margin-left: 25.31646%; }
  .has-two-sidebars .l-region--sidebar-first,
  .has-two-sidebars .l-region--sidebar-second {
    width: 24.05063%;
    float: left;
    margin-right: 1.26582%; }
  .has-two-sidebars .l-region--sidebar-first {
    margin-left: -75.94937%; }
  .has-two-sidebars .l-region--sidebar-second {
    float: right;
    margin-right: 0;
    clear: none; } }

.gallery-grid {
  *zoom: 1;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto; }
  .gallery-grid p {
    display: none; }
  .gallery-grid:before, .gallery-grid:after {
    display: table;
    content: '';
    line-height: 0; }
  .gallery-grid:after {
    clear: both; }
  .gallery-grid figure {
    margin: 0;
    border: 1px solid #ccc;
    min-height: 246px;
    margin-bottom: 1em;
    padding: 4px; }
    .gallery-grid figure br {
      display: none; }
    .gallery-grid figure figcaption {
      font-family: "Roboto", sans-serif;
      font-size: .8em;
      line-height: 1.2; }
    .gallery-grid figure img {
      width: 100% !important;
      height: auto !important; }
    @media screen and (min-width: 800px) {
      .gallery-grid figure {
        float: left;
        display: block;
        margin-right: 2.12766%;
        width: 31.91489%; }
        .gallery-grid figure:last-child {
          margin-right: 0; }
        .gallery-grid figure.three {
          margin-right: 0; }
        .gallery-grid figure.six {
          margin-right: 0; } }

.gallery-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .gallery-row a {
    max-height: 88px;
    overflow: hidden;
    margin-bottom: 10px;
    width: 17%; }
  .gallery-row a img {
    width: 100% !important;
    height: auto !important; }

.keynote {
  *zoom: 1; }
  .keynote:before, .keynote:after {
    display: table;
    content: '';
    line-height: 0; }
  .keynote:after {
    clear: both; }

.keynote {
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 1em;
  margin-bottom: 1em; }
  .keynote .imgp_container {
    width: 100%;
    float: left;
    margin-right: 16px; }
    @media screen and (min-width: 480px) {
      .keynote .imgp_container {
        width: 200px; } }
  .keynote .imgp_container img {
    width: 100% !important;
    height: auto !important; }

.blog__post {
  *zoom: 1;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto; }
  .blog__post:before, .blog__post:after {
    display: table;
    content: '';
    line-height: 0; }
  .blog__post:after {
    clear: both; }
  .blog__post img {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 23.40426%; }
    .blog__post img:last-child {
      margin-right: 0; }
  .blog__post .blog__text {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 74.46809%; }
    .blog__post .blog__text:last-child {
      margin-right: 0; }

.blog__text {
  display: none; }

.blog__videos {
  margin-top: 3em;
  margin-bottom: 3em; }
  .blog__videos h3 {
    display: none; }
  @media screen and (min-width: 800px) {
    .blog__videos .blog__video {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 48.93617%; }
      .blog__videos .blog__video:last-child {
        margin-right: 0; } }

@media screen and (max-width: 900px) {
  #block-islandora-solr-basic-facets {
    padding: 0; }
  #block-islandora-solr-basic-facets .block__content,
  #block-islandora-solr-sort,
  .block--islandora-solr-current-query {
    display: none; }
  .show_me {
    display: block !important; }
  i {
    font-size: 1.4em; }
  .page-islandora-search .l-main {
    display: flex;
    flex-direction: column; }
  .page-islandora-search h1 {
    margin: 0;
    text-align: center; }
  .page-islandora-search .l-content {
    order: 2; }
  .page-islandora-search .has-one-sidebar.has-sidebar-second .l-content {
    float: none;
    margin-right: initial;
    width: initial; }
  .page-islandora-search .has-one-sidebar.has-sidebar-second .l-region--sidebar-second {
    float: none;
    width: initial; }
  .page-islandora-search div#block-islandora-solr-sort {
    margin-top: 0.75em; }
  .page-islandora-search .block__title {
    margin: 0 auto;
    margin: 0 auto !important;
    display: block !important;
    width: 180px;
    text-align: center;
    appearance: none;
    background-color: #8c2004;
    border: 0;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    /*font-family: $button-font;*/
    font-size: .9em;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    line-height: 1;
    padding: 0.375em 0.75em;
    text-decoration: none;
    transition: background-color 150ms ease;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    /*@include small-shadow;*/ }
    .page-islandora-search .block__title:before {
      content: "\f085";
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-right: 4px; }
    .page-islandora-search .block__title:hover, .page-islandora-search .block__title:focus {
      background-color: #701a03;
      color: #fff; }
    .page-islandora-search .block__title:disabled {
      cursor: not-allowed;
      opacity: 0.5; }
      .page-islandora-search .block__title:disabled:hover {
        background-color: #8c2004; } }

ul.sf-menu ul {
  overflow: visible !important; }

.nav-wrapper {
  background: #333;
  border-bottom: 1px solid #262626; }

/*=====================================
=            header search            =
=====================================*/
.nav-wrapper {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #333;
  border-bottom: 1px solid #1a1a1a;
  min-height: 60px;
  width: 100%;
  /*z-index: 999;*/ }
  .nav-wrapper .upei-crest {
    float: left;
    max-height: 60px;
    padding-left: 1em;
    padding-left: 0; }
    @media screen and (min-width: 1037px) {
      .nav-wrapper .upei-crest {
        padding-right: 2em; } }
    .nav-wrapper .upei-crest img {
      max-height: 60px;
      padding: 0.8em 0; }
  .nav-wrapper .search-bar {
    float: right;
    margin-top: 1em;
    width: 12em; }
    .nav-wrapper .search-bar form {
      position: relative; }
      .nav-wrapper .search-bar form input[type='text'] {
        box-sizing: border-box;
        background: #404040;
        border-radius: 6px;
        border: 1px solid #262626;
        color: rgba(255, 255, 255, 0.7);
        font-size: 0.9em;
        font-style: italic;
        margin: 0;
        padding: 0.5em 0.8em;
        width: 100%; }
      .nav-wrapper .search-bar form input[type='submit'], .nav-wrapper .search-bar form .search-facets .block--islandora-solr .islandora-solr-sort a[type='submit'], .search-facets .block--islandora-solr .islandora-solr-sort .nav-wrapper .search-bar form a[type='submit'], .nav-wrapper .search-bar form .islandora-solr-facet-wrapper .soft-limit[type='submit'], .islandora-solr-facet-wrapper .nav-wrapper .search-bar form .soft-limit[type='submit'], .nav-wrapper .search-bar form .block--islandora-solr-current-query .soft-limit[type='submit'], .block--islandora-solr-current-query .nav-wrapper .search-bar form .soft-limit[type='submit'] {
        background: #404040;
        border: none;
        bottom: 0.3em;
        left: auto;
        outline: none;
        padding: 0 9px;
        position: absolute;
        right: 0.3em;
        top: 0.3em;
        overflow: hidden;
        text-indent: 160%;
        white-space: nowrap;
        background-image: url(../images/search-icon.png);
        width: 2em;
        background-size: 19px;
        background-repeat: no-repeat;
        background-position: center; }
    @media screen and (min-width: 1037px) {
      .nav-wrapper .search-bar {
        display: inline-block;
        position: relative;
        width: 16em; }
        .nav-wrapper .search-bar input {
          box-sizing: border-box;
          display: block; } }

.l-region--navigation p {
  margin: 0; }

.l-region--navigation {
  display: none; }

.is-visible .l-region--navigation {
  display: block; }

@media screen and (min-width: 940px) {
  .l-region--navigation {
    display: block; } }

/* ==========================================================================
	superfish menu
   ========================================================================== */
/*
 * Sample SCSS for adding Superfish styles to themes.
  *
   * Instructions:
    * 
	 * 1. Change the #block-superfish-1 (below) to the ID of your Superfish menu.
	  *
	   * 2. Add the SCSS to your theme, either as a separate SCSS file or as a part of the main SCSS file.
	    *
		 * 3. Note that by removing the .sf-style-none, the style will be applied to the menu regardless of
		  *    the Style selected in the block configuration.
		   *
		    */
#block-superfish-1 .sf-style-none {
  float: left;
  margin-top: 1em;
  padding: 0; }
  #block-superfish-1 .sf-style-none.sf-navbar {
    width: 100%;
    background: #333; }
  #block-superfish-1 .sf-style-none ul {
    padding-left: 0; }
    #block-superfish-1 .sf-style-none ul.sf-megamenu li.sf-megamenu-wrapper {
      background: #333; }
      #block-superfish-1 .sf-style-none ul.sf-megamenu li.sf-megamenu-wrapper li {
        background: transparent; }
  #block-superfish-1 .sf-style-none a {
    color: rgba(255, 255, 255, 0.7);
    padding: 0.75em 1em;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 0.9em;
    font-weight: 100; }
    #block-superfish-1 .sf-style-none a.sf-with-ul {
      padding-right: 2.25em; }
    #block-superfish-1 .sf-style-none a:focus {
      background: #333;
      outline: 0;
      color: #ECAD00; }
    #block-superfish-1 .sf-style-none a:hover {
      background: #333;
      outline: 0;
      color: #ECAD00; }
    #block-superfish-1 .sf-style-none a:active {
      background: #333;
      outline: 0;
      color: #ECAD00; }
  #block-superfish-1 .sf-style-none span.nolink {
    color: rgba(255, 255, 255, 0.7);
    padding: 0.75em 1em; }
    #block-superfish-1 .sf-style-none span.nolink.sf-with-ul {
      padding-right: 2.25em; }
    #block-superfish-1 .sf-style-none span.nolink:hover {
      background: #333;
      outline: 0; }
  #block-superfish-1 .sf-style-none span.sf-description {
    color: rgba(255, 255, 255, 0.7);
    display: block;
    font-size: 0.833em;
    line-height: 1.5;
    margin: 5px 0 0 5px;
    padding: 0; }
  #block-superfish-1 .sf-style-none.rtl span.sf-description {
    margin: 5px 5px 0 0; }
  #block-superfish-1 .sf-style-none.rtl span.nolink.sf-with-ul {
    padding-left: 2.25em;
    padding-right: 1em; }
  #block-superfish-1 .sf-style-none.rtl a.sf-with-ul {
    padding-left: 2.25em;
    padding-right: 1em; }
  #block-superfish-1 .sf-style-none li {
    background: #333; }
    #block-superfish-1 .sf-style-none li > ul {
      /*top: 44px;*/
      border-top: 4px solid #ECAD00;
      /*border-bottom: 1px solid $gold; */
      border-bottom: 1px solid #262626; }
      #block-superfish-1 .sf-style-none li > ul a.menuparent:after {
        float: right;
        content: '\f101';
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    #block-superfish-1 .sf-style-none li li {
      background: #333; }
      #block-superfish-1 .sf-style-none li li > ul {
        top: 44px;
        border-top: 0; }
      #block-superfish-1 .sf-style-none li li li {
        background: #333; }
    #block-superfish-1 .sf-style-none li:hover {
      background: #333;
      outline: 0; }
      #block-superfish-1 .sf-style-none li:hover > ul {
        /*top: 40px;*/
        /*border-top: 4px solid $gold;*/ }
    #block-superfish-1 .sf-style-none li.sfHover {
      background: #333;
      outline: 0; }
      #block-superfish-1 .sf-style-none li.sfHover > ul {
        /*top: 44px;*/ }
      #block-superfish-1 .sf-style-none li.sfHover > ul ul {
        top: 0; }
  #block-superfish-1 .sf-style-none .sf-sub-indicator {
    background-image: url("../images/dropdown-arrows.png"); }

#block-superfish-1 div.sf-accordion-toggle.sf-style-none a {
  background: #333;
  color: rgba(255, 255, 255, 0.7);
  padding: 1em; }
  #block-superfish-1 div.sf-accordion-toggle.sf-style-none a.sf-expanded {
    background-color: #333;
    font-weight: bold; }

#block-superfish-1 .sf-menu.sf-style-none.rtl.sf-accordion li li a {
  padding-left: auto;
  padding-right: 2em; }

#block-superfish-1 .sf-menu.sf-style-none.rtl.sf-accordion li li li a {
  padding-left: auto;
  padding-right: 3em; }

#block-superfish-1 .sf-menu.sf-style-none.rtl.sf-accordion li li li li a {
  padding-left: auto;
  padding-right: 4em; }

#block-superfish-1 .sf-menu.sf-style-none.rtl.sf-accordion li li li li li a {
  padding-left: auto;
  padding-right: 5em; }

#block-superfish-1 .sf-menu.sf-style-none.rtl.sf-accordion li li li li li span.nolink {
  padding-left: auto;
  padding-right: 5em; }

#block-superfish-1 .sf-menu.sf-style-none.rtl.sf-accordion li li li li span.nolink {
  padding-left: auto;
  padding-right: 4em; }

#block-superfish-1 .sf-menu.sf-style-none.rtl.sf-accordion li li li span.nolink {
  padding-left: auto;
  padding-right: 3em; }

#block-superfish-1 .sf-menu.sf-style-none.rtl.sf-accordion li li span.nolink {
  padding-left: auto;
  padding-right: 2em; }

#block-superfish-1 .sf-menu.sf-style-none.rtl ul.sf-megamenu li.sf-megamenu-wrapper ol li.sf-megamenu-column {
  float: right; }

#block-superfish-1 .sf-menu.sf-style-none.sf-accordion li.sf-expanded {
  background-color: #333; }
  #block-superfish-1 .sf-menu.sf-style-none.sf-accordion li.sf-expanded > a {
    font-weight: bold; }
  #block-superfish-1 .sf-menu.sf-style-none.sf-accordion li.sf-expanded > span.nolink {
    font-weight: bold; }

#block-superfish-1 .sf-menu.sf-style-none.sf-accordion li li a {
  padding-left: 2em; }

#block-superfish-1 .sf-menu.sf-style-none.sf-accordion li li li a {
  padding-left: 3em; }

#block-superfish-1 .sf-menu.sf-style-none.sf-accordion li li li li a {
  padding-left: 4em; }

#block-superfish-1 .sf-menu.sf-style-none.sf-accordion li li li li li a {
  padding-left: 5em; }

#block-superfish-1 .sf-menu.sf-style-none.sf-accordion li li li li li span.nolink {
  padding-left: 5em; }

#block-superfish-1 .sf-menu.sf-style-none.sf-accordion li li li li span.nolink {
  padding-left: 4em; }

#block-superfish-1 .sf-menu.sf-style-none.sf-accordion li li li span.nolink {
  padding-left: 3em; }

#block-superfish-1 .sf-menu.sf-style-none.sf-accordion li li span.nolink {
  padding-left: 2em; }

#block-superfish-1 .sf-menu.sf-style-none.sf-accordion li a.sf-accordion-button {
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 499; }

#block-superfish-1 .sf-menu.sf-style-none ul.sf-megamenu li.sf-megamenu-wrapper ol {
  margin: 0;
  padding: 0; }
  #block-superfish-1 .sf-menu.sf-style-none ul.sf-megamenu li.sf-megamenu-wrapper ol li {
    margin: 0;
    padding: 0; }
    #block-superfish-1 .sf-menu.sf-style-none ul.sf-megamenu li.sf-megamenu-wrapper ol li.sf-megamenu-column {
      display: inline;
      float: left; }

#block-superfish-1 .sf-menu.sf-style-none ul.sf-megamenu li.sf-megamenu-wrapper a.menuparent {
  font-weight: bold; }

#block-superfish-1 .sf-menu.sf-style-none ul.sf-megamenu li.sf-megamenu-wrapper span.nolink.menuparent {
  font-weight: bold; }

.sf-menu.sf-horizontal.sf-shadow ul {
  padding-right: 0px !important; }

#block-superfish-1 .sf-depth-1 > a {
  padding-bottom: 21px; }

#block-superfish-1 .sf-depth-1.sfHover > a {
  background: #ECAD00;
  color: #333; }

/*=====    section end    ======*/
.main-nav--desktop {
  display: none; }
  @media screen and (min-width: 940px) {
    .main-nav--desktop {
      display: block; } }

.main-nav--mobile {
  clear: both;
  display: block; }
  @media screen and (min-width: 940px) {
    .main-nav--mobile {
      display: none; } }

.pager {
  margin-bottom: 10px; }

.pager__item {
  background: #f9f7f9;
  background: white;
  padding: 3px 8px !important;
  border: 1px solid #ddd;
  border-radius: 4px; }
  @media screen and (min-width: 600px) {
    .pager__item {
      padding: 5px 10px !important; } }

.pager__item:hover,
.pager__item--current {
  background: #e0e8e2;
  -webkit-transition: all 0.15s ease-out 0s;
  -moz-transition: all 0.15s ease-out 0s;
  transition: all 0.15s ease-out 0s; }

.pager__item a {
  color: #444;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  line-height: 0;
  font-size: .8em; }
  @media screen and (min-width: 600px) {
    .pager__item a {
      font-size: .9em; } }

.field-type-paragraphs .button {
  /*padding: 6px 9px;*/
  text-transform: uppercase;
  font-size: .8em;
  font-weight: normal;
  border: 1px solid #b3b3b3; }
  .field-type-paragraphs .button:hover {
    background-color: #e6e6e6;
    color: #333; }

.field-type-paragraphs [id*="actions-remove-button"] {
  height: 32px;
  padding-left: 36px;
  padding-right: 10px;
  background: #ccc url("/sites/lmmi.vre3.upei.ca/themes/lmmi_2015/dist/assets/img/close.png") no-repeat 10px center;
  /*vertical-align: middle;*/
  color: #333;
  cursor: pointer; }

.field-type-paragraphs [id*="actions-edit-button"] {
  height: 32px;
  padding-left: 36px;
  padding-right: 10px;
  background: #ccc url("/sites/lmmi.vre3.upei.ca/themes/lmmi_2015/dist/assets/img/edit.png") no-repeat 10px center;
  /*vertical-align: middle;*/
  color: #333;
  cursor: pointer; }

.field-type-paragraphs [id*="actions-collapse-button"] {
  height: 32px;
  padding-left: 36px;
  padding-right: 10px;
  background: #ccc url("/sites/lmmi.vre3.upei.ca/themes/lmmi_2015/dist/assets/img/collapse.png") no-repeat 10px center;
  /*vertical-align: middle;*/
  color: #333;
  cursor: pointer; }

.field-type-paragraphs .form-actions .button:first-of-type {
  border-right-width: 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px; }

.field-type-paragraphs .form-actions .button:last-of-type {
  margin-left: 0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px; }

.field-type-paragraphs table {
  table-layout: auto; }

.field-type-paragraphs .tabledrag-handle .handle {
  height: 30px;
  width: 30px;
  margin: 0 1em 0 .5em;
  padding: 0;
  background: url("/sites/lmmi.vre3.upei.ca/themes/lmmi_2015/dist/assets/img/reorder.png") no-repeat 6px 9px; }

.field-type-paragraphs .form-actions em {
  background-color: #FFFF00; }

.field-type-paragraphs th {
  padding-right: 1em;
  /* LTR */
  background-color: #bbb;
  text-align: left;
  /* LTR */ }

.field-type-paragraphs tr.even,
.field-type-paragraphs tr.odd {
  background-color: #f5f5f5; }

.field-type-paragraphs tr.odd {
  background-color: #fff; }

.field-type-paragraphs table {
  border: 1px solid #ddd;
  border-top: 0; }

.field-type-paragraphs [id*="paragraph-bundle-title"] {
  text-transform: uppercase;
  font-size: .8em;
  color: #74933E;
  text-decoration: underline;
  margin-bottom: 1em; }

.paragraphs--media-block-right {
  *zoom: 1;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto; }
  .paragraphs--media-block-right:before, .paragraphs--media-block-right:after {
    display: table;
    content: '';
    line-height: 0; }
  .paragraphs--media-block-right:after {
    clear: both; }
  .paragraphs--media-block-right--text {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 74.46809%; }
    .paragraphs--media-block-right--text:last-child {
      margin-right: 0; }
  .paragraphs--media-block-right--image {
    border: 1px solid #ccc;
    padding: 4px;
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 23.40426%; }
    .paragraphs--media-block-right--image:last-child {
      margin-right: 0; }
  .paragraphs--media-block-right--image--caption {
    font-weight: bold; }

.field-type-paragraphs .filter-wrapper {
  display: none; }

.field-type-paragraphs .form-type-textarea {
  margin-bottom: 1em; }

.paragraphs--media-block {
  /*@include media($medium-screen) {*/
  /*@include outer-container;*/
  /*&.img-20 {*/
  /*.paragraphs--media-block--image {*/
  /*@include span-columns(2 of 10);*/
  /*}*/
  /*}*/
  /*&.img-30 {*/
  /*.paragraphs--media-block--image {*/
  /*@include span-columns(3 of 10);*/
  /*@include shift(3 of 10 );*/
  /*}*/
  /*}*/
  /*&.img-40 {*/
  /*.paragraphs--media-block--image {*/
  /*@include span-columns(4 of 10);*/
  /*}*/
  /*}*/
  /*&.img-50 {*/
  /*.paragraphs--media-block--image {*/
  /*@include span-columns(5 of 10);*/
  /*}*/
  /*}*/
  /*&.img-60 {*/
  /*.paragraphs--media-block--image {*/
  /*@include span-columns(6 of 10);*/
  /*}*/
  /*}*/
  /*&.img-70 {*/
  /*.paragraphs--media-block--image {*/
  /*@include span-columns(7 of 10);*/
  /*}*/
  /*}*/
  /*&.img-80 {*/
  /*.paragraphs--media-block--image {*/
  /*@include span-columns(8 of 10);*/
  /*}*/
  /*}*/
  /*}*/ }
  .paragraphs--media-block--image {
    border: 1px solid #ccc;
    padding: 4px;
    margin-bottom: 1em; }
  .paragraphs--media-block--image--caption {
    font-weight: bold; }
  .paragraphs--media-block--text {
    /*@include span-columns(9);*/
    /*@include span-columns( 5 of 10 );*/ }

.paragraphs--media-block {
  display: flex; }
  .paragraphs--media-block img {
    width: 100%;
    height: auto;
    /*order: 2;*/ }
  .paragraphs--media-block.img-20-left .paragraphs--media-block--image {
    margin-right: 1em;
    flex: 2; }
  .paragraphs--media-block.img-20-left .paragraphs--media-block--text {
    flex: 8; }
  .paragraphs--media-block.img-30-left .paragraphs--media-block--image {
    margin-right: 1em;
    flex: 3; }
  .paragraphs--media-block.img-30-left .paragraphs--media-block--text {
    flex: 7; }
  .paragraphs--media-block.img-40-left .paragraphs--media-block--image {
    margin-right: 1em;
    flex: 4; }
  .paragraphs--media-block.img-40-left .paragraphs--media-block--text {
    flex: 6; }
  .paragraphs--media-block.img-50-left .paragraphs--media-block--image {
    margin-right: 1em;
    flex: 1; }
  .paragraphs--media-block.img-50-left .paragraphs--media-block--text {
    flex: 1; }
  .paragraphs--media-block.img-60-left .paragraphs--media-block--image {
    margin-right: 1em;
    flex: 6; }
  .paragraphs--media-block.img-60-left .paragraphs--media-block--text {
    flex: 4; }
  .paragraphs--media-block.img-70-left .paragraphs--media-block--image {
    margin-right: 1em;
    flex: 7; }
  .paragraphs--media-block.img-70-left .paragraphs--media-block--text {
    flex: 3; }
  .paragraphs--media-block.img-80-left .paragraphs--media-block--image {
    flex: 8;
    margin-right: 1em; }
  .paragraphs--media-block.img-80-left .paragraphs--media-block--text {
    flex: 2; }
  .paragraphs--media-block.img-20-right .paragraphs--media-block--image {
    flex: 2;
    order: 2; }
  .paragraphs--media-block.img-20-right .paragraphs--media-block--text {
    flex: 8;
    order: 1; }
  .paragraphs--media-block.img-30-right .paragraphs--media-block--image {
    margin-left: 1em;
    flex: 3;
    order: 2; }
  .paragraphs--media-block.img-30-right .paragraphs--media-block--text {
    flex: 7;
    order: 1; }
  .paragraphs--media-block.img-40-right .paragraphs--media-block--image {
    margin-left: 1em;
    flex: 4;
    order: 2; }
  .paragraphs--media-block.img-40-right .paragraphs--media-block--text {
    flex: 6;
    order: 1; }
  .paragraphs--media-block.img-50-right .paragraphs--media-block--image {
    margin-left: 1em;
    flex: 1;
    order: 2; }
  .paragraphs--media-block.img-50-right .paragraphs--media-block--text {
    flex: 1;
    order: 1; }
  .paragraphs--media-block.img-60-right .paragraphs--media-block--image {
    margin-left: 1em;
    flex: 6;
    order: 2; }
  .paragraphs--media-block.img-60-right .paragraphs--media-block--text {
    flex: 4;
    order: 1; }
  .paragraphs--media-block.img-70-right .paragraphs--media-block--image {
    margin-left: 1em;
    flex: 7;
    order: 2; }
  .paragraphs--media-block.img-70-right .paragraphs--media-block--text {
    flex: 3;
    order: 1; }
  .paragraphs--media-block.img-80-right .paragraphs--media-block--image {
    margin-left: 1em;
    flex: 8;
    order: 2; }
  .paragraphs--media-block.img-80-right .paragraphs--media-block--text {
    flex: 2;
    order: 1; }

@media screen and (min-width: 800px) {
  .field-name-field-conference-image {
    width: 40%;
    float: left;
    margin-right: 1em; } }

.view-conferences .views-row {
  border-bottom: 1px solid #ccc;
  padding-top: 1em; }

.view-conferences .moscone-container {
  margin-bottom: 0; }

.view-conferences .media-object {
  display: none;
  border: 1px solid #ccc;
  padding: 10px;
  background: #fff; }
  @media screen and (min-width: 800px) {
    .view-conferences .media-object {
      display: block; } }
  .view-conferences .media-object .media-object-section:first-child {
    line-height: 0; }

.view-conferences figure {
  background: #fff; }

.field--name-field-podcast .entity {
  background: rgba(142, 173, 144, 0.27);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 10px;
  margin-bottom: 1rem; }

.paragraphs-items-field-podcast .field-item {
  background: rgba(142, 173, 144, 0.27);
  /* border: 1px solid #ccc; */
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 10px; }

.view-conferences .panel-display.moscone.clearfix {
  border-bottom: 0px;
  background: rgba(142, 173, 144, 0.27);
  /* border: 1px solid #ccc; */
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 10px; }

.gallery-row a {
  border: 1px solid #ccc;
  padding: 4px;
  background: #fff; }

/*reset*/
.tabs--primary {
  border-bottom: 0; }
  .tabs--primary li {
    margin: 0;
    padding: 4p; }
  .tabs--primary a {
    padding: 0;
    margin: 0;
    background: none; }
    .tabs--primary a:hover, .tabs--primary a:focus {
      background: none; }
    .tabs--primary a.active {
      background: none; }

.tabs--primary a {
  /*font-family: "Merriweather Sans", sans-serif;*/
  font-size: .8em;
  font-weight: 100; }

.tabs--primary li {
  padding: 5px;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc; }
  .tabs--primary li:last-child {
    border-right: 1px solid #ccc;
    border-radius: 0 4px 4px 0; }
  .tabs--primary li:first-child {
    border-radius: 4px 0 0 4px; }
  .tabs--primary li.active, .tabs--primary li:hover {
    background-color: #8c2004; }
  .tabs--primary li.active a, .tabs--primary li:hover a {
    color: #fff; }

.publication-teaser .node--teaser {
  *zoom: 1;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  /*margin-bottom:2em !important;*/ }
  .publication-teaser .node--teaser:before, .publication-teaser .node--teaser:after {
    display: table;
    content: '';
    line-height: 0; }
  .publication-teaser .node--teaser:after {
    clear: both; }
  .publication-teaser .node--teaser h3 {
    font-weight: 700; }

.publication-teaser .field-name-field-feature-image {
  width: 100%; }
  .publication-teaser .field-name-field-feature-image img {
    width: 100%; }

@media screen and (min-width: 600px) {
  .publication-teaser .field-name-title {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 100%;
    margin-right: 0; }
    .publication-teaser .field-name-title:last-child {
      margin-right: 0; }
  .publication-teaser .field-name-field-feature-image {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 14.89362%; }
    .publication-teaser .field-name-field-feature-image:last-child {
      margin-right: 0; }
  .publication-teaser .field-name-body {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 82.97872%;
    margin-right: 0; }
    .publication-teaser .field-name-body:last-child {
      margin-right: 0; } }

.publication-teaser .field-name-node-link {
  text-align: right; }

.publication-teaser .views-row {
  border: 1px solid #ddd;
  background: rgba(142, 173, 144, 0.27);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 10px;
  margin-bottom: 1.5em;
  margin-bottom: 1em; }

.pane-featured-publications {
  clear: both; }

.twilight-book {
  display: none;
  line-height: 1;
  width: 188px;
  float: right;
  margin-left: 1em; }
  @media screen and (min-width: 800px) {
    .twilight-book {
      display: block; } }
  .twilight-book .caption {
    display: block; }
  .twilight-book img {
    width: 100%; }

/**
 * $SEARCH-FORM
 */
.search-block-form .form-text {
  width: auto;
  padding: 0.4em; }

form.islandora-solr-simple-search-form {
  position: relative; }
  form.islandora-solr-simple-search-form input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    box-sizing: border-box;
    -webkit-transition: border-color;
    -moz-transition: border-color;
    transition: border-color;
    background-color: white;
    border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #ddd;
    display: block;
    font-size: 1em;
    font-style: italic;
    margin: 0;
    padding: 0.5em 0.5em;
    position: relative;
    width: 100%; }
  form.islandora-solr-simple-search-form input[type=submit], form.islandora-solr-simple-search-form .search-facets .block--islandora-solr .islandora-solr-sort a[type='submit'], .search-facets .block--islandora-solr .islandora-solr-sort form.islandora-solr-simple-search-form a[type='submit'], form.islandora-solr-simple-search-form .islandora-solr-facet-wrapper .soft-limit[type='submit'], .islandora-solr-facet-wrapper form.islandora-solr-simple-search-form .soft-limit[type='submit'], form.islandora-solr-simple-search-form .block--islandora-solr-current-query .soft-limit[type='submit'], .block--islandora-solr-current-query form.islandora-solr-simple-search-form .soft-limit[type='submit'] {
    background-color: #8c2004;
    border-radius: 3px;
    border: 0;
    color: white;
    display: inline-block;
    font-size: inherit;
    font-weight: bold;
    padding: 7px 18px;
    text-decoration: none;
    background-clip: padding-box;
    position: absolute;
    top: 0em 0em 0em;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    outline: none;
    padding: 5px 10px; }
    form.islandora-solr-simple-search-form input[type=submit]:hover:not(:disabled), form.islandora-solr-simple-search-form .search-facets .block--islandora-solr .islandora-solr-sort a[type=submit]:hover:not(:disabled), .search-facets .block--islandora-solr .islandora-solr-sort form.islandora-solr-simple-search-form a[type=submit]:hover:not(:disabled), form.islandora-solr-simple-search-form .islandora-solr-facet-wrapper .soft-limit[type=submit]:hover:not(:disabled), .islandora-solr-facet-wrapper form.islandora-solr-simple-search-form .soft-limit[type=submit]:hover:not(:disabled), form.islandora-solr-simple-search-form .block--islandora-solr-current-query .soft-limit[type=submit]:hover:not(:disabled), .block--islandora-solr-current-query form.islandora-solr-simple-search-form .soft-limit[type=submit]:hover:not(:disabled) {
      background-color: #a82401;
      cursor: pointer; }
    form.islandora-solr-simple-search-form input[type=submit]:active:not(:disabled), form.islandora-solr-simple-search-form .search-facets .block--islandora-solr .islandora-solr-sort a[type=submit]:active:not(:disabled), .search-facets .block--islandora-solr .islandora-solr-sort form.islandora-solr-simple-search-form a[type=submit]:active:not(:disabled), form.islandora-solr-simple-search-form .islandora-solr-facet-wrapper .soft-limit[type=submit]:active:not(:disabled), .islandora-solr-facet-wrapper form.islandora-solr-simple-search-form .soft-limit[type=submit]:active:not(:disabled), form.islandora-solr-simple-search-form .block--islandora-solr-current-query .soft-limit[type=submit]:active:not(:disabled), .block--islandora-solr-current-query form.islandora-solr-simple-search-form .soft-limit[type=submit]:active:not(:disabled), form.islandora-solr-simple-search-form input[type=submit]:focus:not(:disabled), form.islandora-solr-simple-search-form .search-facets .block--islandora-solr .islandora-solr-sort a[type=submit]:focus:not(:disabled), .search-facets .block--islandora-solr .islandora-solr-sort form.islandora-solr-simple-search-form a[type=submit]:focus:not(:disabled), form.islandora-solr-simple-search-form .islandora-solr-facet-wrapper .soft-limit[type=submit]:focus:not(:disabled), .islandora-solr-facet-wrapper form.islandora-solr-simple-search-form .soft-limit[type=submit]:focus:not(:disabled), form.islandora-solr-simple-search-form .block--islandora-solr-current-query .soft-limit[type=submit]:focus:not(:disabled), .block--islandora-solr-current-query form.islandora-solr-simple-search-form .soft-limit[type=submit]:focus:not(:disabled) {
      background-color: #711c06;
      cursor: pointer; }
    form.islandora-solr-simple-search-form input[type=submit]:disabled, form.islandora-solr-simple-search-form .search-facets .block--islandora-solr .islandora-solr-sort a[type=submit]:disabled, .search-facets .block--islandora-solr .islandora-solr-sort form.islandora-solr-simple-search-form a[type=submit]:disabled, form.islandora-solr-simple-search-form .islandora-solr-facet-wrapper .soft-limit[type=submit]:disabled, .islandora-solr-facet-wrapper form.islandora-solr-simple-search-form .soft-limit[type=submit]:disabled, form.islandora-solr-simple-search-form .block--islandora-solr-current-query .soft-limit[type=submit]:disabled, .block--islandora-solr-current-query form.islandora-solr-simple-search-form .soft-limit[type=submit]:disabled {
      cursor: not-allowed;
      opacity: 0.5; }
    form.islandora-solr-simple-search-form input[type=submit] img, form.islandora-solr-simple-search-form .search-facets .block--islandora-solr .islandora-solr-sort a[type='submit'] img, .search-facets .block--islandora-solr .islandora-solr-sort form.islandora-solr-simple-search-form a[type='submit'] img, form.islandora-solr-simple-search-form .islandora-solr-facet-wrapper .soft-limit[type='submit'] img, .islandora-solr-facet-wrapper form.islandora-solr-simple-search-form .soft-limit[type='submit'] img, form.islandora-solr-simple-search-form .block--islandora-solr-current-query .soft-limit[type='submit'] img, .block--islandora-solr-current-query form.islandora-solr-simple-search-form .soft-limit[type='submit'] img {
      height: 12px;
      opacity: 0.7; }

#islandora-solr-simple-search-form label {
  display: none; }

ul.breadcrumb {
  display: none  !important; }

.page-islandora-search h1 {
  display: inline-block; }

.page-islandora-search div#islandora-solr-result-count {
  display: inline-block;
  margin-left: .5em; }

.l-region--sidebar-first {
  display: none; }

#islandora-solr-top {
  min-height: initial; }

.pager {
  margin-bottom: 1.5em; }

.islandora-solr-search-result {
  background-color: #e0e8e2;
  border: 1px solid #ccc; }
  .islandora-solr-search-result dl.solr-thumb {
    width: 25%;
    padding: .5em; }
    .islandora-solr-search-result dl.solr-thumb img {
      width: 100%;
      padding: 4px;
      background: #fff; }
  .islandora-solr-search-result dl.solr-fields {
    border-color: #ccc !important;
    width: 75%; }
  .islandora-solr-search-result .solr-fields dt {
    font-family: "Roboto Condensed", sans-serif; }
  .islandora-solr-search-result .solr-fields dt,
  .islandora-solr-search-result .solr-fields dd {
    border-top: 1px solid #ccc; }

.facet-button, .search-facets .block--islandora-solr .islandora-solr-sort a, .search-facets .block--islandora-solr .islandora-solr-range-slider input[type="submit"], .search-facets .block--islandora-solr .islandora-solr-range-slider .islandora-solr-sort a, .search-facets .block--islandora-solr .islandora-solr-sort .islandora-solr-range-slider a, .search-facets .block--islandora-solr .islandora-solr-range-slider .islandora-solr-facet-wrapper .soft-limit, .islandora-solr-facet-wrapper .search-facets .block--islandora-solr .islandora-solr-range-slider .soft-limit, .search-facets .block--islandora-solr .islandora-solr-range-slider .block--islandora-solr-current-query .soft-limit, .block--islandora-solr-current-query .search-facets .block--islandora-solr .islandora-solr-range-slider .soft-limit, .islandora-solr-facet-wrapper .soft-limit, .block--islandora-solr-current-query .soft-limit {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  padding: 0.5em 0.75em 0.4em;
  font-size: .9em;
  text-transform: uppercase; }

.search-facets h3 {
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1.3em;
  margin-bottom: 0.4em; }

.search-facets .block--islandora-solr .islandora-solr-sort {
  margin: 0;
  padding: 0;
  border: 0;
  *zoom: 1;
  text-align: center;
  margin: 0 auto;
  padding: 0;
  margin-bottom: 1.5em; }
  .search-facets .block--islandora-solr .islandora-solr-sort:before, .search-facets .block--islandora-solr .islandora-solr-sort:after {
    display: table;
    content: '';
    line-height: 0; }
  .search-facets .block--islandora-solr .islandora-solr-sort:after {
    clear: both; }
  .search-facets .block--islandora-solr .islandora-solr-sort li {
    list-style-image: none;
    list-style-type: none;
    margin-left: 0;
    white-space: nowrap;
    display: inline;
    float: left;
    padding-left: 4px;
    padding-right: 4px; }
    .search-facets .block--islandora-solr .islandora-solr-sort li:first-child, .search-facets .block--islandora-solr .islandora-solr-sort li.first {
      padding-left: 0; }
    .search-facets .block--islandora-solr .islandora-solr-sort li:last-child {
      padding-right: 0; }
    .search-facets .block--islandora-solr .islandora-solr-sort li.last {
      padding-right: 0; }
  .search-facets .block--islandora-solr .islandora-solr-sort li {
    width: 50%; }
  .search-facets .block--islandora-solr .islandora-solr-sort a {
    width: 100%;
    border: 1px solid #ddd;
    background: #e0e8e2;
    margin: 0 auto;
    display: table;
    color: #6f6f6f; }
    .search-facets .block--islandora-solr .islandora-solr-sort a:hover {
      background-color: #74933E; }
    .search-facets .block--islandora-solr .islandora-solr-sort a img {
      float: right; }
    .search-facets .block--islandora-solr .islandora-solr-sort a:before {
      content: "Sort by "; }
    .search-facets .block--islandora-solr .islandora-solr-sort a.active {
      background-color: #74933E;
      color: #fff; }
      .search-facets .block--islandora-solr .islandora-solr-sort a.active:hover, .search-facets .block--islandora-solr .islandora-solr-sort a.active:focus {
        background-color: #5d7632;
        color: #fff; }

.search-facets .block--islandora-solr .islandora-solr-range-slider input[type="submit"], .search-facets .block--islandora-solr .islandora-solr-range-slider .islandora-solr-sort a, .search-facets .block--islandora-solr .islandora-solr-sort .islandora-solr-range-slider a, .search-facets .block--islandora-solr .islandora-solr-range-slider .islandora-solr-facet-wrapper .soft-limit, .islandora-solr-facet-wrapper .search-facets .block--islandora-solr .islandora-solr-range-slider .soft-limit, .search-facets .block--islandora-solr .islandora-solr-range-slider .block--islandora-solr-current-query .soft-limit, .block--islandora-solr-current-query .search-facets .block--islandora-solr .islandora-solr-range-slider .soft-limit {
  background-color: #74933E; }
  .search-facets .block--islandora-solr .islandora-solr-range-slider input[type="submit"]:hover, .search-facets .block--islandora-solr .islandora-solr-range-slider .islandora-solr-sort a:hover, .search-facets .block--islandora-solr .islandora-solr-sort .islandora-solr-range-slider a:hover, .search-facets .block--islandora-solr .islandora-solr-range-slider .islandora-solr-facet-wrapper .soft-limit:hover, .islandora-solr-facet-wrapper .search-facets .block--islandora-solr .islandora-solr-range-slider .soft-limit:hover, .search-facets .block--islandora-solr .islandora-solr-range-slider .block--islandora-solr-current-query .soft-limit:hover, .block--islandora-solr-current-query .search-facets .block--islandora-solr .islandora-solr-range-slider .soft-limit:hover, .search-facets .block--islandora-solr .islandora-solr-range-slider input[type="submit"]:focus, .search-facets .block--islandora-solr .islandora-solr-range-slider .islandora-solr-sort a:focus, .search-facets .block--islandora-solr .islandora-solr-sort .islandora-solr-range-slider a:focus, .search-facets .block--islandora-solr .islandora-solr-range-slider .islandora-solr-facet-wrapper .soft-limit:focus, .islandora-solr-facet-wrapper .search-facets .block--islandora-solr .islandora-solr-range-slider .soft-limit:focus, .search-facets .block--islandora-solr .islandora-solr-range-slider .block--islandora-solr-current-query .soft-limit:focus, .block--islandora-solr-current-query .search-facets .block--islandora-solr .islandora-solr-range-slider .soft-limit:focus {
    background-color: #5d7632;
    color: #fff; }

.search-facets .block--islandora-solr .date-range-filter-wrapper {
  background: #ccc;
  padding: 5px; }

.search-facets .block--islandora-solr li {
  font-size: 1em; }

.search-facets .block--islandora-solr .islandora-solr-date-filter label {
  display: inline-block;
  float: left;
  margin: 5px 0;
  width: 100%; }

.search-facets #block-islandora-solr-sort {
  border: 0;
  margin: 0;
  padding: 0; }

.islandora-solr-facet-wrapper, .block--islandora-solr-current-query {
  *zoom: 1; }
  .islandora-solr-facet-wrapper:before, .block--islandora-solr-current-query:before, .islandora-solr-facet-wrapper:after, .block--islandora-solr-current-query:after {
    display: table;
    content: '';
    line-height: 0; }
  .islandora-solr-facet-wrapper:after, .block--islandora-solr-current-query:after {
    clear: both; }

.block ul.islandora-solr-facet {
  padding: 0 0 0 0; }

ul.islandora-solr-facet li > a {
  display: inline-block; }

ul.islandora-solr-facet .count {
  font-family: "Roboto Condensed", sans-serif; }

.islandora-solr-facet-wrapper, .block--islandora-solr-current-query {
  margin-bottom: 1.5em;
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px 10px; }

.page-islandora-search .l-content {
  padding: 0; }

.islandora-solr-facet-wrapper .soft-limit, .block--islandora-solr-current-query .soft-limit {
  background-color: #74933E;
  color: #fff;
  display: table;
  margin: 5px auto;
  /*font-family: "Roboto Condensed", sans-serif;*/
  /*font-weight: 400;*/
  /*padding: 0.5em 0.75em 0.4em;*/
  /*font-size:.9em;*/
  /*text-transform: uppercase;*/ }
  .islandora-solr-facet-wrapper .soft-limit:hover, .block--islandora-solr-current-query .soft-limit:hover, .islandora-solr-facet-wrapper .soft-limit:focus, .block--islandora-solr-current-query .soft-limit:focus {
    background-color: #5d7632;
    color: #fff; }

.islandora-solr-facet input {
  font-weight: 400; }

input#edit-range-slider-submit {
  display: table;
  margin: 5px auto; }

.islandora-solr-facet + a.soft-limit:before {
  content: "\f139";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 4px; }

.islandora-solr-facet + a.soft-limit:before {
  color: white !important;
  margin-right: 4px; }

.islandora-solr-facet.hidden + a.soft-limit:before {
  content: "\f13a";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 4px; }

.islandora-solr-facet.hidden + a.soft-limit i {
  color: white !important;
  margin-right: 4px; }

.islandora-solr-facet li a {
  font-family: "Roboto Condensed", sans-serif;
  /*font-size: 1em;*/
  width: 68%; }

.islandora-solr-facet li {
  display: flex;
  justify-content: space-between; }

ul.islandora-solr-facet {
  /*font-size: .9em;*/ }
  ul.islandora-solr-facet li {
    border-bottom: 1px solid rgba(204, 204, 204, 0);
    border-top: 1px solid rgba(204, 204, 204, 0); }
  ul.islandora-solr-facet li:hover {
    background: #e0e8e2;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc; }

.strikethrough {
  text-decoration: line-through !important; }

.islandora-solr-breadcrumb-super {
  vertical-align: super;
  font-size: 80%; }

#block-islandora-solr-basic-facets .fa {
  color: #333; }
  #block-islandora-solr-basic-facets .fa:hover {
    color: #74933E; }

.block--islandora-solr-current-query ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.block--islandora-solr-current-query li {
  font-family: "Roboto Condensed", sans-serif;
  font-size: .9em;
  background: #e0e8e2;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  margin-bottom: 2px;
  padding: 0 4px; }

.block--islandora-solr-current-query i {
  color: #8c2004 !important; }

.collection-tile {
  background: #e0e8e2;
  /*padding: $small-spacing;*/
  margin-bottom: 1.5em;
  border: 1px solid #ddd; }
  .collection-tile img {
    padding: 4px;
    border: 1px solid #ddd;
    background: #fff; }

.collection-tile {
  display: flex;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }
  .collection-tile dl.solr-thumb {
    flex-basis: 310px; }
  .collection-tile dl.solr-thumb img {
    width: 100%; }
  .collection-tile dd, .collection-tile dt {
    display: inline-block;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    padding-top: 6px;
    padding-bottom: 4px;
    margin: 0; }
  .collection-tile .collection-tile.metadata dt {
    border-top: 1px solid; }
  .collection-tile dl.solr-fields.islandora-inline-metadata dt, .collection-tile dl.solr-fields.islandora-inline-metadata dd {
    border-top: 1px solid #ccc; }
  .collection-tile dl.solr-fields.islandora-inline-metadata {
    border-top: 3px solid #ccc; }
  .collection-tile dl.solr-thumb {
    margin-top: 0;
    padding: 4px 4px; }
  .collection-tile dl.solr-thumb {
    padding: 0;
    margin: 0; }
  .collection-tile dl.solr-thumb dt {
    padding: 0;
    margin: 0;
    padding: 8px 8px 0 8px; }

.flex-caption {
  background: #404040;
  color: #fff;
  padding: 10px; }

div#flexslider-1 {
  box-shadow: none !important;
  margin: 0;
  border: 0; }

.slider img {
  border: 2px solid #ccc; }

ul.breadcrumb {
  display: inline-block; }

.page-sub-nav {
  position: relative;
  float: right; }
  .page-sub-nav .menu {
    padding-left: 0;
    margin: 0; }
  .page-sub-nav h2, .page-sub-nav .search-facets h3, .search-facets .page-sub-nav h3 {
    display: inline-block;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1em;
    font-weight: 500; }
  .page-sub-nav .menu-block-wrapper {
    display: none;
    /*background: #ccc;*/
    width: 250px;
    padding: 10px;
    position: absolute;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    right: 0;
    z-index: 999;
    border: 1px solid #ccc;
    background: #e0e9e1; }
    .page-sub-nav .menu-block-wrapper li {
      list-style: none; }
      .page-sub-nav .menu-block-wrapper li .active a {
        color: #74933E;
        background: #fff; }
      .page-sub-nav .menu-block-wrapper li li {
        padding-left: 18px; }
      .page-sub-nav .menu-block-wrapper li a {
        font-size: 1em;
        font-family: "Roboto Condensed", sans-serif;
        color: #333;
        display: block;
        border-bottom: 1px solid #ccc;
        padding: 5px 0; }
        .page-sub-nav .menu-block-wrapper li a:hover {
          color: #74933E;
          background: #fff; }
    .page-sub-nav .menu-block-wrapper li.expanded > a {
      font-weight: 600;
      color: #38471E; }

/**
 * @file
 * Styles for system messages.
 */
div.messages {
  background-position: 8px 8px;
  /* LTR */
  background-repeat: no-repeat;
  border: 1px solid;
  margin: 6px 0;
  padding: 10px 10px 10px 50px;
  /* LTR */ }

div.status {
  background-image: url(../../../../../misc/message-24-ok.png);
  border-color: #be7; }

div.status,
.ok {
  color: #234600; }

div.status,
table tr.ok {
  background-color: #f8fff0; }

div.warning {
  background-image: url(../../../../../misc/message-24-warning.png);
  border-color: #ed5; }

div.warning,
.warning {
  color: #840; }

div.warning,
table tr.warning {
  background-color: #fffce5; }

div.error {
  background-image: url(../../../../../misc/message-24-error.png);
  border-color: #ed541d; }

div.error,
.error {
  color: #8c2e0b; }

div.error,
table tr.error {
  background-color: #fef5f1; }

div.error p.error {
  color: #333; }

div.messages ul {
  margin: 0 0 0 1em;
  /* LTR */
  padding: 0; }

div.messages ul li {
  list-style-image: none; }

.tweets {
  font-size: 14px; }

.tweets .views-row {
  margin-bottom: 10px;
  padding-bottom: 10px;
  line-height: 1.3;
  border-bottom: 1px solid #ccc; }

/**
 * Styles to render a tweet as it is shown at Twitter.com.
 *
 * Thanks @makangus.
 */
div.view-tweets ul {
  list-style-type: none;
  padding: 0px; }

.twitter-status {
  background: #ffffff;
  color: #333333;
  padding: 10px 10px 0;
  margin-bottom: 2px;
  font-size: .9em;
  /*font-size: 1.077em;*/
  line-height: 1.42857142857143em; }

.twitter-status .timestamp {
  float: right;
  color: #999999;
  font-size: 0.85714285714286em; }

.twitter-status .name-handle {
  margin-left: 60px; }

.twitter-status .text {
  margin-left: 60px; }

.twitter-status .name-handle .name, .twitter-status .name-handle .handle {
  display: inline; }

.twitter-status .name-handle .name a {
  font-weight: bold;
  margin-right: 5px;
  color: #333333; }

.twitter-status .name-handle .handle a {
  color: #999999;
  font-size: 0.85714285714286em; }

.twitter-status .avatar {
  float: left; }

.twitter-status .avatar img {
  border-radius: 3px; }

.twitter-status a {
  color: #30a9ff; }

.twitter-status a:hover {
  text-decoration: underline; }

.twitter-status .actions {
  text-align: right;
  font-size: 0.85714285714286em;
  padding: 0;
  margin: 0;
  visibility: hidden; }

.twitter-status:hover .actions {
  visibility: visible; }

.twitter-status .actions li {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0; }

.twitter-status .actions a {
  display: inline-block;
  line-height: 16px;
  margin-left: 10px;
  vertical-align: top;
  color: #999999; }

body {
  background: url("https://raw.githubusercontent.com/thoughtbot/refills/master/source/images/textures/inverted/subtle_grunge.png"), linear-gradient(180deg, #ffffff, #e6e6e6), center no-repeat white scroll; }

.l-main {
  background: #fff;
  border: 1px solid #ddd;
  padding-top: 10px; }

.mini-panel--footer {
  *zoom: 1;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2em; }
  .mini-panel--footer:before, .mini-panel--footer:after {
    display: table;
    content: '';
    line-height: 0; }
  .mini-panel--footer:after {
    clear: both; }
  @media screen and (min-width: 800px) {
    .mini-panel--footer .block--upei-logo {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 23.40426%; }
      .mini-panel--footer .block--upei-logo:last-child {
        margin-right: 0; }
    .mini-panel--footer .block--supporters {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 23.40426%; }
      .mini-panel--footer .block--supporters:last-child {
        margin-right: 0; }
    .mini-panel--footer .block--contact {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 23.40426%; }
      .mini-panel--footer .block--contact:last-child {
        margin-right: 0; }
    .mini-panel--footer .menu--social-links {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 23.40426%; }
      .mini-panel--footer .menu--social-links:last-child {
        margin-right: 0; } }

.block--upei-logo img {
  max-width: 180px; }

.block--contact a, .block--contact span.gc-cs-link {
  color: #ECAD00; }

.l-footer {
  background: #333;
  padding-top: 1.5em;
  padding-bottom: 1.5em; }

.mini-panel--footer {
  color: white; }
  .mini-panel--footer p {
    color: white; }
  .mini-panel--footer .pane-title {
    font-size: 1.4em; }
  .mini-panel--footer .menu li {
    color: white;
    margin-bottom: .5em;
    /*margin-left: 20px;*/
    list-style-type: none;
    font-family: "Roboto Condensed", sans-serif;
    list-style-image: none; }
    .mini-panel--footer .menu li a {
      color: #fff; }
      .mini-panel--footer .menu li a:hover {
        color: #ECAD00; }
    .mini-panel--footer .menu li i {
      margin-right: 6px;
      font-size: 1.6em;
      color: #ECAD00; }

.menu--social-links {
  color: white; }
  .menu--social-links p {
    color: white; }
  .menu--social-links .pane-title {
    font-size: 1.4em; }
  .menu--social-links .menu li {
    color: white;
    margin-bottom: .5em;
    margin-left: 20px;
    list-style-type: none;
    list-style-image: none; }
    .menu--social-links .menu li a {
      color: #fff; }
      .menu--social-links .menu li a:hover {
        color: #ECAD00; }
    .menu--social-links .menu li i {
      margin-right: 6px;
      font-size: 1.6em;
      color: #ECAD00; }

div#footer-wrapper {
  background: #333; }

.upei-footer a {
  color: green; }

.upei-footer h2, .upei-footer .search-facets h3, .search-facets .upei-footer h3 {
  font-size: 20px;
  color: #fff; }

.upei-footer .bottom-links a {
  color: #ccc; }

.links-last li a {
  color: #ccc; }

.upei-footer .links-last, .upei-footer .links-last a {
  color: #ccc;
  font-family: "Roboto", sans-serif;
  font-weight: 100; }

.upei-footer {
  font-size: 13px; }

.bottom-links li {
  line-height: 142%; }

.upei-footer .address {
  color: #fff;
  margin-bottom: 1.5em; }

.upei-footer .links-last {
  margin-bottom: 1.5em; }

.upei-footer ul li.leaf {
  list-style-image: none;
  list-style-type: none;
  margin: 0px; }

.upei-footer ul {
  margin: 0px; }

.l-footer ul {
  padding: 0; }
  .l-footer ul li {
    font-family: "Roboto", sans-serif; }

.contact-info p {
  font-family: "Roboto Condensed", sans-serif; }

.footer-login a {
  background: #737272;
  padding: 5px;
  border-radius: 4px;
  font-weight: 400; }
  .footer-login a:hover {
    background: #74933E; }

.l-header {
  background: #38471E; }

@media screen and (min-width: 664px) {
  a.site-logo {
    display: inline-block;
    width: 285px;
    float: left;
    height: auto;
    border-right: 1px solid rgba(255, 255, 255, 0.42);
    padding-right: 10px;
    margin-right: 10px; } }

.branding-container.container {
  padding-top: 10px;
  padding-bottom: 10px; }

nav.social {
  margin: 0;
  padding: 0;
  border: 0;
  *zoom: 1;
  display: none; }
  nav.social:before, nav.social:after {
    display: table;
    content: '';
    line-height: 0; }
  nav.social:after {
    clear: both; }
  nav.social li {
    list-style-image: none;
    list-style-type: none;
    margin-left: 0;
    white-space: nowrap;
    display: inline;
    float: left;
    padding-left: 4px;
    padding-right: 4px; }
    nav.social li:first-child, nav.social li.first {
      padding-left: 0; }
    nav.social li:last-child {
      padding-right: 0; }
    nav.social li.last {
      padding-right: 0; }
  nav.social ul.menu {
    list-style: none;
    float: right;
    margin: 0; }
    nav.social ul.menu li {
      list-style-image: none;
      list-style-type: none;
      margin-left: 0; }
  nav.social span.title {
    display: none; }
  nav.social a {
    font-size: 2em;
    color: #ffecb9;
    padding-left: 10px; }
    nav.social a:hover {
      color: #ECAD00; }
  @media screen and (min-width: 880px) {
    nav.social {
      display: block; } }

/*old site link*/
/*nav.social li.last a {*/
/*font-size: 1em;*/
/*}*/
/*nav.social li.last i {*/
/*margin-right: 4px;*/
/*}*/
.search-bar {
  *zoom: 1; }
  .search-bar:before, .search-bar:after {
    display: table;
    content: '';
    line-height: 0; }
  .search-bar:after {
    clear: both; }

.lp-welcome img {
  display: block;
  margin: 0 auto; }

.lp-welcome h2, .lp-welcome .search-facets h3, .search-facets .lp-welcome h3 {
  font-size: 1.7em;
  margin-top: .6em; }
  @media screen and (min-width: 800px) {
    .lp-welcome h2, .lp-welcome .search-facets h3, .search-facets .lp-welcome h3 {
      margin-top: 0; } }

.tweets h2:before, .tweets .search-facets h3:before, .search-facets .tweets h3:before {
  content: "\f099";
  margin-right: 4px;
  /* font-size: 4em; */
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  color: #38471E; }

.layout-container--landing-page {
  *zoom: 1;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto; }
  .layout-container--landing-page:before, .layout-container--landing-page:after {
    display: table;
    content: '';
    line-height: 0; }
  .layout-container--landing-page:after {
    clear: both; }
  @media screen and (min-width: 800px) {
    .layout-container--landing-page .slider {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 65.95745%; }
      .layout-container--landing-page .slider:last-child {
        margin-right: 0; }
    .layout-container--landing-page .lp-welcome {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 31.91489%;
      margin-right: 0; }
      .layout-container--landing-page .lp-welcome:last-child {
        margin-right: 0; }
    .layout-container--landing-page .lp-feature {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 31.91489%; }
      .layout-container--landing-page .lp-feature:last-child {
        margin-right: 0; }
    .layout-container--landing-page .lp-blog {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 31.91489%; }
      .layout-container--landing-page .lp-blog:last-child {
        margin-right: 0; }
    .layout-container--landing-page .tweets {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 31.91489%;
      margin-right: 0; }
      .layout-container--landing-page .tweets:last-child {
        margin-right: 0; }
    .layout-container--landing-page .lp-video {
      clear: both;
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 65.95745%; }
      .layout-container--landing-page .lp-video:last-child {
        margin-right: 0; }
    .layout-container--landing-page .exhibits {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 31.91489%;
      margin-right: 0; }
      .layout-container--landing-page .exhibits:last-child {
        margin-right: 0; } }
