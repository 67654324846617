@mixin input-icon($icon-url, $icon-size: 16px, $icon-inset: 10px, $bg-color: #ccc, $text-color: #333) {
  height: $icon-size * 2;
  padding-left: $icon-size + $icon-inset * 2;
  padding-right: $icon-inset;
  background: $bg-color url($icon-url) no-repeat 10px center;

  /*vertical-align: middle;*/
  color: $text-color;
  cursor: pointer;
}

.field-type-paragraphs {
  .button {
    /*padding: 6px 9px;*/
    text-transform: uppercase;
    font-size: .8em;
    font-weight: normal;
    border: 1px solid darken(#ccc, 10%);
    &:hover {
      background-color: lighten(#ccc, 10%);
      color: #333;
    }
  }

  [id*="actions-remove-button"] {
    @include input-icon("/sites/lmmi.vre3.upei.ca/themes/lmmi_2015/dist/assets/img/close.png");
  }

  [id*="actions-edit-button"] {
    @include input-icon("/sites/lmmi.vre3.upei.ca/themes/lmmi_2015/dist/assets/img/edit.png");
  }

  [id*="actions-collapse-button"] {
    @include input-icon("/sites/lmmi.vre3.upei.ca/themes/lmmi_2015/dist/assets/img/collapse.png");
  }

  // button bar

  .form-actions {
    .button:first-of-type {
      border-right-width: 0;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .button:last-of-type {
    
      margin-left: 0;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    
    
    }
  }

  [id*="actions-edit-button"] {}

  [id*="actions-remove-button"] {}

  table {
    table-layout: auto;
  }

  .tabledrag-handle .handle {
    height: 30px;
    width: 30px;
    margin: 0 1em 0 .5em;
    padding: 0;
    background: url("/sites/lmmi.vre3.upei.ca/themes/lmmi_2015/dist/assets/img/reorder.png") no-repeat 6px 9px;
  }

  .form-actions {
    em {
      background-color: #FFFF00;
    }
  }

  th {
    padding-right: 1em;

    /* LTR */
    background-color: #bbb;
    text-align: left;

    /* LTR */
  }

  tr.even,
  tr.odd {
    background-color: #f5f5f5;
  }

  tr.odd {
    background-color: #fff;
  }

  table {
    border: 1px solid #ddd;
    border-top: 0;
  }

  [id*="paragraph-bundle-title"] {
    text-transform: uppercase;
    font-size: .8em;
    color: $light-green;
    text-decoration: underline;
    margin-bottom: 1em;
  }
}
