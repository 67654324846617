
.row {
  max-width: 62.5rem;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
  .row .row,
  .column-row .row {
    margin-left: -0.625rem;
    margin-right: -0.625rem; }
    @media screen and (min-width: 40em) {
      .row .row,
      .column-row .row {
        margin-left: -0.9375rem;
        margin-right: -0.9375rem; } }
  .row.expanded {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-left: 0;
    padding-right: 0; }

.column, .columns {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  -webkit-flex: 1 1 0px;
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; }
  @media screen and (min-width: 40em) {
    .column, .columns {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem; } }

.small-1 {
  -webkit-flex: 0 0 8.33333%;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  -webkit-flex: 0 0 16.66667%;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  -webkit-flex: 0 0 25%;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  -webkit-flex: 0 0 33.33333%;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  -webkit-flex: 0 0 41.66667%;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  -webkit-flex: 0 0 58.33333%;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  -webkit-flex: 0 0 66.66667%;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  -webkit-flex: 0 0 75%;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  -webkit-flex: 0 0 83.33333%;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  -webkit-flex: 0 0 91.66667%;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-order-1 {
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1; }

.small-order-2 {
  -webkit-order: 2;
      -ms-flex-order: 2;
          order: 2; }

.small-order-3 {
  -webkit-order: 3;
      -ms-flex-order: 3;
          order: 3; }

.small-order-4 {
  -webkit-order: 4;
      -ms-flex-order: 4;
          order: 4; }

.small-order-5 {
  -webkit-order: 5;
      -ms-flex-order: 5;
          order: 5; }

.small-order-6 {
  -webkit-order: 6;
      -ms-flex-order: 6;
          order: 6; }

.small-collapse > .column, .small-collapse > .columns {
  padding-left: 0;
  padding-right: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-left: 0.625rem;
  padding-right: 0.625rem; }

@media screen and (min-width: 40em) {
  .medium-1 {
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-order-1 {
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .medium-order-2 {
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .medium-order-3 {
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .medium-order-4 {
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .medium-order-5 {
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .medium-order-6 {
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; } }

@media screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px; } }

.row.medium-unstack .column, .row.medium-unstack .columns {
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%; }
  @media screen and (min-width: 40em) {
    .row.medium-unstack .column, .row.medium-unstack .columns {
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px; } }

@media screen and (min-width: 40em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; } }

@media screen and (min-width: 64em) {
  .large-1 {
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-order-1 {
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .large-order-2 {
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .large-order-3 {
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .large-order-4 {
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .large-order-5 {
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .large-order-6 {
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; } }

@media screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px; } }

.row.large-unstack .column, .row.large-unstack .columns {
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%; }
  @media screen and (min-width: 64em) {
    .row.large-unstack .column, .row.large-unstack .columns {
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px; } }

@media screen and (min-width: 64em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; } }

.shrink {
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto; }

.row.align-right {
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.row.align-center {
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }

.row.align-justify {
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.row.align-spaced {
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around; }

.row.align-top {
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start; }

.column.align-top, .align-top.columns {
  -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
          align-self: flex-start; }

.row.align-bottom {
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end; }

.column.align-bottom, .align-bottom.columns {
  -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
          align-self: flex-end; }

.row.align-middle {
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.column.align-middle, .align-middle.columns {
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center; }

.row.align-stretch {
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; }

.column.align-stretch, .align-stretch.columns {
  -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
          align-self: stretch; }

.media-object {
  margin-bottom: 1rem;
  display: block; }
  .media-object img {
    max-width: none; }
  @media screen and (min-width: 0em) and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      display: block;
      padding: 0;
      padding-bottom: 1rem; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  display: table-cell;
  vertical-align: top; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not( + .media-object-section:first-child) {
    padding-left: 1rem; }
  .media-object-section.middle {
    vertical-align: middle; }
  .media-object-section.bottom {
    vertical-align: bottom; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    content: ' ';
    display: table; }
  .pagination::after {
    clear: both; }
  .pagination li {
    font-size: 0.875rem;
    margin-right: 0.0625rem;
    display: none;
    border-radius: 3px; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    color: #0a0a0a;
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 3px; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #2ba6cb;
    color: #fefefe;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: default; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    content: '…';
    padding: 0.1875rem 0.625rem;
    color: #0a0a0a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  content: '«';
  display: inline-block;
  margin-right: 0.5rem; }

.pagination-next a::after,
.pagination-next.disabled::after {
  content: '»';
  display: inline-block;
  margin-left: 0.5rem; }
