//--------------------------
//-  mobile facet display  -
//--------------------------
@include media(max-width 900px) {
	//Hide/close on mobile. this is tied to a jquery behavior// {{{
	#block-islandora-solr-basic-facets {
		padding: 0;
	}

	#block-islandora-solr-basic-facets .block__content,
	#block-islandora-solr-sort,
	.block--islandora-solr-current-query {
		display: none;
	}

	.show_me {
		display: block !important;
	}

	i {
		font-size: 1.4em;
	}

	.page-islandora-search {
		.l-main {
			display: flex;
			flex-direction: column;
		}

		h1 {
			margin: 0;
			text-align: center;
		}

		#islandora-solr-result-count {}

		aside.l-region.l-region--sidebar-second {}

		.l-content {
			order: 2;
		}

		.has-one-sidebar.has-sidebar-second .l-content {
			float: none;
			margin-right: initial;
			width: initial;
		}

		.has-one-sidebar.has-sidebar-second .l-region--sidebar-second {
			float: none;
			width: initial;
		}

		div#block-islandora-solr-sort {
			margin-top: $base-spacing / 2;
		}

		.block__title {
			@include icon(f085);

			// {{{

			margin: 0 auto;
			margin: 0 auto !important;
			display: block !important;
			width: 180px;
			text-align: center;
			appearance: none;
			background-color: $action-color;
			border: 0;
			border-radius: $base-border-radius;
			color: #fff;
			cursor: pointer;
			/*font-family: $button-font;*/
			font-size: .9em;
			text-transform: uppercase;
			-webkit-font-smoothing: antialiased;
			font-weight: 400;
			line-height: 1;
			padding: ($small-spacing / 2) ($base-spacing / 2);
			text-decoration: none;
			transition: background-color $base-duration $base-timing;
			user-select: none;
			vertical-align: middle;
			white-space: nowrap;

			/*@include small-shadow;*/

			&:hover,
			&:focus {
				background-color: shade($action-color, 20%);
				color: #fff;
			}

			&:disabled {
				cursor: not-allowed;
				opacity: 0.5;

				&:hover {
					background-color: $action-color;
				}
			}

			// }}}
	
		}
	}

	// }}}
}
