#islandora-solr-simple-search-form label {
    display: none;
  }
ul.breadcrumb {
	display: none  !important;
}

.page-islandora-search {
	h1 {
		display: inline-block;
	}

	div#islandora-solr-result-count {
		display: inline-block;
		margin-left: .5em;
	}
}

.l-region--sidebar-first {
	display: none;
}

#islandora-solr-top {
	min-height: initial;
}

.pager {
	margin-bottom: $base-spacing;
}

//--------------------
//-  search results  -
//--------------------
$search-border: #e0e8e2;

.islandora-solr-search-result {
	background-color: $pale-green;
	border: 1px solid #ccc;

	dl.solr-thumb {
		width: 25%;
		padding: .5em;

		img {
			width: 100%;
			padding: 4px;
			background: #fff;

			//margin: $small-spacing;
	
		}
	}

	dl.solr-fields.islandora-inline-metadata {}

	dl.solr-fields {
		border-color: #ccc !important;

		//margin-left: $small-spacing;
		width: 75%;
	}

	.solr-fields dt {
		font-family: $sans-serif-2;
	}

	.solr-fields dt,
	.solr-fields dd {
		border-top: 1px solid #ccc;
	}

	.islandora-solr-search-result-inner {
		//display: flex;

	}

	.solr-thumb {
		//flex-basis: 60%;

	}
}

//--------------------------------------------
//-  common button syles for facet butttons  -
//--------------------------------------------

.facet-button {
	//box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.2);
	font-family: $sans-serif-2;
	font-weight: 400;
	padding: 0.5em 0.75em 0.4em;
	font-size: .9em;
	text-transform: uppercase;
}

//=============================================
.search-facets {
	h2 {}

	h3 {
		font-family: "Roboto Condensed", sans-serif;
		line-height: 1.3em;
		margin-bottom: 0.4em;

		@extend h2;
	}

	.block--islandora-solr {
		.islandora-solr-sort {
			@include horizontal-list;

			text-align: center;
			margin: 0 auto;
			padding: 0;
			margin-bottom: $base-spacing;

			li {
				width: 50%;
			}

			a {
				width: 100%;

				@extend input[type="submit"];

				@extend .facet-button;

				border: $base-border;

				//background-color: #484848;
				//background: #ccc;
				background: $pale-green;
				margin: 0 auto;
				display: table;
				color: #6f6f6f;

				&:hover {
					background-color: $light-green;
				}

				img {
					float: right;
				}

				&:before {
					content: "Sort by ";
				}

				&.active {
					$action-color: $light-green;

					//box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.2);
					background-color: $action-color;
					color: #fff;

					&:hover,
					&:focus {
						background-color: shade($action-color, 20%);
						color: #fff;
					}
				}
			}
		}

		.islandora-solr-range-slider {
			input[type="submit"] {
				@extend .facet-button;

				$action-color: $light-green;

				background-color: $action-color;

				&:hover,
				&:focus {
					background-color: shade($action-color, 20%);
					color: #fff;
				}
			}
		}

		.date-range-filter-wrapper {
			background: #ccc;
			padding: 5px;
		}

		li {
			font-size: 1em;
		}

		.islandora-solr-date-filter label {
			display: inline-block;
			float: left;
			margin: 5px 0;
			width: 100%;
		}
	}

	#block-islandora-solr-sort {
		border: 0;
		margin: 0;
		padding: 0;
	}
}

//***********************************************
.islandora-solr-facet-wrapper {
	@include clearfix;
}

.block ul.islandora-solr-facet {
	padding: 0 0 0 0;
}

ul.islandora-solr-facet li > a {
	display: inline-block;
}

ul.islandora-solr-facet .count {
	font-family: $roboto_condensed;
}

.islandora-solr-facet-wrapper {
	margin-bottom: $base-spacing;
	background: #fff;
	border: $base-border;
	padding: 5px 10px;
}

.page-islandora-search .l-content {
	padding: 0;
}

.islandora-solr-facet-wrapper .soft-limit {
	@extend input[type="submit"];

	@extend .facet-button;

	$action-color: $light-green;

	background-color: $action-color;
	color: #fff;
	display: table;
	margin: 5px auto;

	&:hover,
	&:focus {
		background-color: shade($action-color, 20%);
		color: #fff;
	}

	/*font-family: "Roboto Condensed", sans-serif;*/
	/*font-weight: 400;*/
	/*padding: 0.5em 0.75em 0.4em;*/
	/*font-size:.9em;*/
	/*text-transform: uppercase;*/
}

.islandora-solr-facet input {
	font-weight: 400;
}

input#edit-range-slider-submit {
	display: table;
	margin: 5px auto;
}

//add icons to the 'more' dropdown button

.islandora-solr-facet + a.soft-limit {
	@include icon(f139);

	&:before {
		color: white !important;
		margin-right: 4px;
	}
}

.islandora-solr-facet.hidden + a.soft-limit {
	@include icon(f13a);

	i {
		color: white !important;
		margin-right: 4px;
	}
}

ul.islandora-solr-facet {}

.islandora-solr-facet li a {
	font-family: $roboto_condensed;

	/*font-size: 1em;*/
	width: 68%;
}

.islandora-solr-facet li {
	display: flex;
	justify-content: space-between;
}

ul.islandora-solr-facet {
	/*font-size: .9em;*/

	li {
		border-bottom: 1px solid rgba(204, 204, 204, 0);
		border-top: 1px solid rgba(204, 204, 204, 0);
	}

	li:hover {
		background: $pale-green;
		border-bottom: 1px solid #ccc;
		border-top: 1px solid #ccc;
	}
}

.strikethrough {
	text-decoration: line-through !important;
}

.islandora-solr-breadcrumb-super {
	vertical-align: super;
	font-size: 80%;
}

#block-islandora-solr-basic-facets .fa {
	color: $dark-gray;

	&:hover {
		color: $light-green;
	}
}

//-------------------------
//-  current query block  -
//-------------------------
.block--islandora-solr-current-query {
	@extend .islandora-solr-facet-wrapper;

	ul {
		list-style: none;

		@include clean;
	}
}

.block--islandora-solr-current-query li {
	font-family: $roboto_condensed;
	font-size: .9em;
	background: $pale-green;
	border-bottom: 1px solid #ccc;
	border-top: 1px solid #ccc;
	margin-bottom: 2px;
	padding: 0 4px;
}

.block--islandora-solr-current-query i {
	color: $red !important;
}

//------------------------------------
//-  Ryrie-Campbell collection view-
//------------------------------------
.collection-tile {
	background: $pale-green;
	/*padding: $small-spacing;*/
	margin-bottom: $base-spacing;

		border: $base-border;
	img {
		padding: 4px;
		border: $base-border;
		background: #fff;
	}
}

.collection-tile {
	display: flex;
@include shadow();
	dl.solr-thumb {
		flex-basis: 310px;
	}

	dl.solr-thumb img {
		width: 100%;
	}

	dd, dt {
		display: inline-block;
		zoom: 1;
		letter-spacing: normal;
		word-spacing: normal;
		vertical-align: top;
		padding-top: 6px;
		padding-bottom: 4px;
		margin: 0;
	}
	.collection-tile.metadata dt {
	    border-top: 1px solid;
	}

	dl.solr-fields.islandora-inline-metadata dt, dl.solr-fields.islandora-inline-metadata dd {
	    border-top: 1px solid #ccc;
	}

	dl.solr-fields.islandora-inline-metadata {
	    border-top: 3px solid #ccc;
	}

	dl.solr-thumb {
	    margin-top: 0;
	    padding: 4px 4px;
	}

	dl.solr-thumb {
	    padding: 0;
	    margin: 0;
	}

	dl.solr-thumb dt {
	    padding: 0;
	    margin: 0;
	    padding: 8px 8px 0 8px;
	}
}

