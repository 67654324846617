ul.breadcrumb {
	display: inline-block;
}

.page-sub-nav {
	position: relative;
	float: right;

	.menu {
		padding-left: 0;
		margin: 0;
	}

	h2 {
		display: inline-block;
		font-family: $sans-serif-2;
		font-size: 1em;
		font-weight: 500;
	}

	.menu-block-wrapper {
		display: none;

		/*background: #ccc;*/
		width: 250px;
		padding: 10px;
		position: absolute;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
		right: 0;
		z-index: 999;
		border: 1px solid #ccc;
		background: rgb(224, 233, 225);

		li {
			list-style: none;

				.active a {
					color: $light-green;
					background: #fff;
				}
			li {
				padding-left: 18px;
			}

			a {
				font-size: 1em;
				font-family: $sans-serif-2;
				color: #333;
				display: block;
				border-bottom: 1px solid #ccc;
				padding: 5px 0;

				&:hover {
					color: $light-green;
					background: #fff;
				}
			}
		}

		li.expanded > a {
			font-weight: 600;
			color: #38471E;
		}
	}
}
